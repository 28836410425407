import { io } from 'socket.io-client';

let url =
 process.env.REACT_APP_ENV === 'production'
  ? process.env.REACT_APP_PROD_SOCKET_URL
  : process.env.REACT_APP_DEV_SOCKET_URL;

let socket_path =
 process.env.REACT_APP_ENV === 'production' ? '/web/web/socket.io' : '/web/socket.io';

let client = localStorage.getItem('client');
let access_token = null;

if (client !== null) {
 client = JSON.parse(client);
 access_token = client.access_token;
}

const socket = io(url, {
 path: socket_path,
 transports: ['websocket'],
 autoConnect: false,
 auth: (cb) => {
  cb({ access_token: `Bearer ${access_token}`, client: 'admin' });
 }
});

export default socket;
