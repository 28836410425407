import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';
import { postCreateBonus } from '../../api/bonus.api';
import { getClubs } from '../../api/clubs.api';

function CreateForm() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Create Bonus',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  club: '-',
  bonusType: 'daily',
  bonusAmount: ''
 });

 const [clubs, setClubs] = useState([]);
 const [missions, setMissions] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeMissionHandler = (index, e) => {
  const name = e.target.name;
  const value = e.target.value;

  let temp = [...missions];
  const element = temp[index];
  element[name] = value;

  temp[index] = element;
  setMissions(temp);
 };

 const getClubList = async () => {
  const response = await getClubs();
  if (response.status !== 200) errorHandler(response);
  else setClubs(response.data);
 };

 const onChangeFormHandler = (element) => {
  const name = element.target.name;
  const value = element.target.value;

  setState({
   ...state,
   [name]: value
  });
 };

 const submitHandler = async () => {
  let request = {
   club: state.club,
   bonusType: state.bonusType,
   bonusAmount: state.bonusAmount,
   missions
  };

  if (missions.length < 1) {
   alert('Please add the mission before submitting');
   return;
  }

  const response = await postCreateBonus(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/bonus');
  }
 };

 const addMissionHandler = () => {
  let element = {
   title: '',
   criteriaType: 'login',
   criteriaValue: '',
   platform: '',
   shouldComplete: ''
  };
  let temp = [...missions];
  temp.push(element);
  setMissions(temp);
 };

 const removeMissionHandler = (index) => {
  const temp = [...missions];
  temp.splice(index, 1);
  setMissions(temp);
 };

 useEffect(() => {
  getClubList();
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row" style={{ width: '90%' }}>
      <div className="row mb-3">
       <div className="col-sm">
        <label htmlFor="club" className="form-label">
         Club
        </label>
        <select
         name="club"
         className="form-select"
         id="club"
         value={state.club}
         onChange={onChangeFormHandler}>
         <option value="-" disabled>
          -
         </option>
         {clubs.map((el) => {
          return (
           <option value={el.name} key={el.id} style={{ textTransform: 'capitalize' }}>
            {el.name}
           </option>
          );
         })}
        </select>
       </div>
       <div className="col-sm">
        <label htmlFor="bonusType" className="form-label">
         Bonus Type
        </label>
        <select
         name="bonusType"
         className="form-select"
         id="bonusType"
         value={state.bonusType}
         onChange={onChangeFormHandler}>
         <option value="daily">Daily</option>
         <option value="weekly">Weekly</option>
        </select>
       </div>
       <div className="col-sm">
        <label htmlFor="bonusAmount" className="form-label">
         Bonus Amount
        </label>
        <input
         type="number"
         className="form-control"
         placeholder="Ex: 100"
         id="bonusAmount"
         name="bonusAmount"
         value={state.bonusAmount}
         onChange={onChangeFormHandler}
        />
       </div>
      </div>

      <div className="row mb-3" style={{ margin: '30px 0 30px' }}>
       <div className="col-sm" style={{ padding: 0 }}>
        <h4>
         Missions
         <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: '15px', padding: '2px' }}
          onClick={addMissionHandler}>
          Add
         </button>
        </h4>
       </div>
      </div>

      <div className="col-sm">
       {missions.length > 0 ? (
        missions.map((element, index) => {
         let spendPlatform = false;
         const criteriaValueText = {
          placeholder: 'Ex: 200',
          text: ''
         };

         if (element.criteriaType === 'login') {
          criteriaValueText.placeholder = 'Ex: 5';
          criteriaValueText.text = 'Target value';
         } else if (element.criteriaType === 'online') {
          criteriaValueText.text = 'In Minutes';
         } else if (element.criteriaType === 'spent') {
          criteriaValueText.text = 'In Amount';
          spendPlatform = true;
         }

         return (
          <div key={index} style={{ background: '#e9ecef', padding: '10px', marginBottom: '25px' }}>
           <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)' }}>
            <h4 style={{ margin: '5px 0 20px' }}>Mission {index + 1}</h4>
            <button
             type="button"
             className="btn btn-danger"
             style={{ width: '83px', height: '45px', justifySelf: 'end' }}
             onClick={() => removeMissionHandler(index)}>
             Remove
            </button>
           </div>

           <div className="form-group">
            <label htmlFor="title_label" className="form-label">
             Title
            </label>
            <input
             type="text"
             className="form-control"
             placeholder="Ex: Login for 2 days"
             id="title_label"
             name="title"
             value={element.title}
             onChange={(e) => onChangeMissionHandler(index, e)}
            />
           </div>

           <div className="form-group" style={{ display: 'flex', gap: '0 20px', margin: '20px 0' }}>
            <div className="col-md-6" style={{ width: '32%' }}>
             <label htmlFor="criteria_type_label" className="form-label">
              Criteria Type
             </label>
             <select
              name="criteriaType"
              className="form-select"
              id="criteria_type_label"
              value={element.criteriaType}
              onChange={(e) => onChangeMissionHandler(index, e)}>
              <option value="login">Login</option>
              <option value="spent">Spent</option>
              <option value="online">Online</option>
             </select>
            </div>

            <div className="col-md-6" style={{ width: '32%' }}>
             <label
              htmlFor="criteria_type_value"
              className="form-label"
              style={{ fontWeight: 'bold' }}>
              {criteriaValueText.text}
             </label>
             <input
              type="number"
              className="form-control"
              id="criteria_type_value"
              placeholder={criteriaValueText.placeholder}
              name="criteriaValue"
              value={element.criteriaValue}
              onChange={(e) => onChangeMissionHandler(index, e)}
             />
            </div>

            {spendPlatform ? (
             <div className="col-md-6" style={{ width: '32%' }}>
              <label htmlFor="platform_label" className="form-label" style={{ fontWeight: 'bold' }}>
               Select the platform
              </label>

              <select
               name="platform"
               className="form-select"
               id="platform_label"
               value={element.platform}
               onChange={(e) => onChangeMissionHandler(index, e)}>
               <option value="games">All Games</option>
               <option value="keno">Keno</option>
               <option value="fish">Fish</option>
               <option value="slot">Slot</option>
              </select>
             </div>
            ) : null}
           </div>

           <div className="col-md-6" style={{ margin: '20px 0' }}>
            <label htmlFor="can_complete_label" className="form-label">
             Can Complete
            </label>
            <input
             type="number"
             className="form-control"
             id="can_complete_label"
             placeholder="Ex: 2"
             name="shouldComplete"
             value={element.shouldComplete}
             onChange={(e) => onChangeMissionHandler(index, e)}
            />
           </div>
          </div>
         );
        })
       ) : (
        <div style={{ minHeight: '100px', background: '#e9ecef', margin: '5px 0 30px' }}>
         <h6 style={{ textAlign: 'center', paddingTop: '35px' }}>No Missions Available</h6>
        </div>
       )}
      </div>

      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Create Bonus
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
