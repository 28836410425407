import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getGames(query) {
 try {
  let { data, status } = await axios.get(`${api}/games?name=${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getGameWithID(query) {
 try {
  let { data, status } = await axios.get(`${api}/game/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateGame(request) {
 try {
  let { data, status } = await axios.post(`${api}/game/create`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdateGame(request) {
 try {
  let { data, status } = await axios.patch(`${api}/game/update`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchGameStatus(request) {
 try {
  let { data, status } = await axios.post(`${api}/game/status/update`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { getGames, getGameWithID, postCreateGame, patchUpdateGame, patchGameStatus };
