import React, { useState } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';
import { postCreateAnnouncements } from '../../api/announcement.api';

function CreateForm() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Create Announcement',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  // announcement_title: "",
  announcement_text: '',
  announcement_type: '-'
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   //   history.push({
   //     pathname: "/auth/login",
   //     state: { details: "Login Expires !! Re-Enter the credentials." }
   //   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  const response = await postCreateAnnouncements(state);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/announcements');
  }
 };

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="announcement_type_label" className="form-label">
        Announcement Type
       </label>
       <select
        name="announcement_type"
        className="form-select"
        id="announcement_type_label"
        value={state.announcement_type}
        onChange={onChangeFormHandler}>
        <option value="-" disabled>
         -
        </option>
        <option value="DISTRIBUTOR">DISTRIBUTOR</option>
        <option value="REGULAR">REGULAR</option>
        <option value="BONUS">BONUS</option>
       </select>
      </div>
      {/* <div className="mb-3">
              <label htmlFor="announcement_title_label" className="form-label">
                Announcement Title
              </label>
              <input
                type="text"
                className="form-control"
                id="announcement_title_label"
                name="announcement_title"
                value={state.announcement_title}
                onChange={onChangeFormHandler}
              />
            </div> */}
      <div className="mb-3">
       <label htmlFor="announcement_textarea_label" className="form-label">
        Announcement Text
       </label>
       <textarea
        className="form-control"
        id="announcement_textarea_label"
        rows="13"
        name="announcement_text"
        onChange={onChangeFormHandler}
        value={state.announcement_text}></textarea>
      </div>
      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Create Announcements
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
