import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

// Req-Res Report

async function getGameTransactionAudit() {
 try {
  let { data, status } = await axios.get(`${api}/transaction/audit-logs`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Req-Res Report

async function getGameTransactionAuditWithUsername(query) {
 try {
  let { data, status } = await axios.get(`${api}/transaction/audit-logs/filter?username=${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Req-Res Report

async function postGameTransactionAuditFilter(request) {
 try {
  let { data, status } = await axios.post(`${api}/transaction/audit-logs/filter`, request);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getGameTransactionAudit,
 getGameTransactionAuditWithUsername,
 postGameTransactionAuditFilter
};
