import React, { useState } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';
import { postCreatePlayer } from '../../api/players.api';

function CreateForm() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Create Player',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  email: '',
  username: '',
  password: '',
  balance: 0
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   //   history.push({
   //     pathname: "/auth/login",
   //     state: { details: "Login Expires !! Re-Enter the credentials." }
   //   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  let request = {
   email: state.email,
   username: state.username,
   password: state.password,
   credit: parseFloat(state.balance)
  };
  const response = await postCreatePlayer(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/players');
  }
 };

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row row g-3">
      <div className="col-md-4">
       <label htmlFor="email_label" className="form-label">
        Email
       </label>
       <input
        type="text"
        className="form-control"
        id="email_label"
        name="email"
        value={state.email}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-4">
       <label htmlFor="username_label" className="form-label">
        Username
       </label>
       <input
        type="text"
        className="form-control"
        id="username_label"
        name="username"
        value={state.username}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-4">
       <label htmlFor="password_label" className="form-label">
        Password
       </label>
       <input
        type="password"
        className="form-control"
        id="password_label"
        name="password"
        value={state.password}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-4">
       <label htmlFor="balance_label" className="form-label">
        Points
       </label>
       <input
        type="number"
        className="form-control"
        id="balance_label"
        name="balance"
        value={state.balance}
        onChange={onChangeFormHandler}
       />
      </div>
      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Create Player
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
