import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import Layout from '../Common/Layout';
import Header from '../Common/Header';

import ChatBox from './ChatBox';
import Conversation from './Conversation';
import socket from '../../socket/index';

import { media_content, offer_content } from '../../utils/content_modify';

import { getMediaLinks } from '../../api/media_links.api';

function Chat() {
 const navigate = useNavigate();

 const errorHandler = async function (response) {
  if (response.status === 401) {
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const fileInputRef = useRef(null);

 const [conversations, setConversations] = useState([]);
 const [selectedConversation, setSelectedConversation] = useState({});

 const [medias, setMedias] = useState([]);
 const [offers, setOffers] = useState([]);
 const [messages, setMessages] = useState([]);

 const [messageInput, setMessageInput] = useState('');
 const [conversationLoader, setConversationLoader] = useState(true);
 const [messageLoader, setMessageLoader] = useState(false);

 const handleFileUpload = async () => {
  // Handle file upload
  const file = fileInputRef.current.files[0];

  let request = {
   file_name: file.name,
   file: file,
   user_id: selectedConversation.user_id,
   is_file: true,
   content: 'null'
  };

  if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
   toast.error('Please select the valid image', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   return false;
  }

  socket.emit('chat:send_message', request);
 };

 // Get all the conversation with messages
 socket.on('chat:agent_player_conversation_response', (response) => {
  setConversationLoader(false);
  setConversations(response.data);
  if (conversations.length !== 0) setSelectedConversation(conversations[0]);
 });

 socket.on('chat:send_message_response', (payload) => {
  if (payload.success) {
  }
 });

 // From Player-To-Admin
 socket.on('chat:new_message', (payload) => {
  console.log('Line 85');
  let temp_messages = [...messages];
  temp_messages.unshift(payload);
  setMessages(temp_messages);
 });

 //  socket.on('chat:update_message', (payload) => {
 //   let temp_messages = [...messages];
 //   temp_messages.unshift(payload);
 //   setMessages(temp_messages);
 //  });

 socket.on('chat:fetch_conversation_response', (response) => {
  setMessageLoader(false);
  setMessages(response.data);
 });

 // Error Handling is pending
 socket.on('connect_error', (err) =>
  toast.error(err.message, {
   position: 'top-right',
   autoClose: 500,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined
  })
 );

 socket.on('connect_failed', (err) => console.log(err));
 socket.on('disconnect', (err) => console.log(err));
 socket.on('error', function (err) {
  console.log('An unexpected error occured: ', err);
  toast.error(err.error, {
   position: 'top-right',
   autoClose: 500,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined
  });
 });

 const setActiveHandler = async (index) => {
  setMessageLoader(true);
  const selected = conversations[index];
  setSelectedConversation(selected);
 };

 const messageInputHandler = async (e) => {
  setMessageInput(e.target.value);
 };

 const sendMessageHandler = async (e) => {
  e.preventDefault();

  if (messageInput.trim() !== '') {
   let request = {
    content: messageInput,
    user_id: selectedConversation.user_id,
    is_file: false
   };
   socket.emit('chat:send_message', request);
   setMessageInput('');
  }
 };

 let getAllMediaLinks = async () => {
  let response = await getMediaLinks();
  if (response.status !== 200) errorHandler(response);
  else setMedias(response.data);
 };

 let sendMediaLinksHandler = async (index) => {
  let selected = medias[index];

  let text = media_content(selected);

  let request = {
   content: text,
   user_id: selectedConversation.user_id,
   is_file: false
  };
  socket.emit('chat:send_message', request);
 };

 let offersHandler = async (index) => {
  let selected = medias[index];
  let text = offer_content(selected);

  let request = {
   content: text,
   user_id: selectedConversation.user_id,
   is_file: false
  };
  socket.emit('chat:send_message', request);
 };

 useEffect(() => {
  getAllMediaLinks();
  socket.connect();
  socket.emit('chat:agent_player_conversation');

  return () => {
   socket.disconnect();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  let request = {
   user_id: selectedConversation.user_id
  };
  setMessages([]);
  if (request.user_id !== undefined) {
   socket.emit('chat:fetch_conversation', request);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [selectedConversation]);

 const header_metadata = {
  heading: null,
  button: false,
  link: '',
  text: ''
 };

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div
    className="container chat"
    style={{
     maxWidth: '93%',
     margin: '0 auto',
     background: '#fff',
     marginTop: '20px'
    }}>
    <div className="row">
     <div className="col" style={{ width: '20vw', maxWidth: '20vw' }}>
      <h5 style={{ paddingLeft: '5px', paddingBottom: '10px' }}>Conversations</h5>
      <div className="conversation--wrapper">
       <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '153px', left: '200px' }}>
         <RotatingLines
          strokeColor="#208fff"
          strokeWidth="5"
          animationDuration="0.75"
          width="45"
          visible={conversationLoader}
         />
        </div>
       </div>

       {/* Left Side: List of conversations */}
       {conversations.map((element, i) => {
        let isActive = false;
        if (selectedConversation.entityId === element.entityId) isActive = true;
        return (
         <div key={element.entityId} onClick={() => setActiveHandler(i)}>
          <ChatBox
           key={element.entityId}
           username={element.username}
           random_image="/avatars/9.jpg"
           set_active={isActive}
          />
         </div>
        );
       })}
      </div>
     </div>

     <div className="col" style={{ width: '55vw', maxWidth: '55vw' }}>
      {Object.keys(selectedConversation).length > 1 ? (
       <>
        {/* Conversation Box */}
        <Conversation
         player_user={selectedConversation.username}
         loader={messageLoader}
         messages={messages}
        />
        <form>
         <div className="media-links--wrapper">
          {medias.map((el, i) => {
           return (
            <div key={el.id} className="media-content" onClick={() => sendMediaLinksHandler(i)}>
             {el.title}
            </div>
           );
          })}
         </div>
         <div className="conversation-sendmessage--container">
          <div style={{ margin: '10px 0 10px 10px' }}>
           <input
            type="text"
            id="send-message-input"
            placeholder="Enter message"
            value={messageInput}
            onChange={messageInputHandler}
           />
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)' }}>
           <div>
            <input
             ref={fileInputRef}
             onChange={handleFileUpload}
             type="file"
             accept="image/*"
             style={{ display: 'none' }}
            />
            <button
             type="button"
             className="btn btn-primary"
             id="send-message-btn"
             onClick={() => fileInputRef.current.click()}>
             Upload File
            </button>
           </div>
           <div>
            <button
             type="submit"
             className="btn btn-primary"
             id="send-message-btn"
             onClick={sendMessageHandler}>
             Send
            </button>
           </div>
          </div>
         </div>
        </form>
       </>
      ) : null}
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default Chat;
