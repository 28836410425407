import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../Common/Header';
import { getFortuneWheelWithID, patchUpdateFortuneWheel } from '../../api/fortunewheel.api';

function UpdateForm() {
 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Fortune Wheel',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  club: '-',
  title: ''
 });

 const [wheelValues, setWheelValues] = useState([
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' },
  { value: '', chance: '' }
 ]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  let payload = {
   id: params.id,
   title: state.title
  };

  const values = [];
  const weights = [];

  for (let i = 0; i < wheelValues.length; i++) {
   const element = wheelValues[i];

   values.push(`${element.value}`);
   weights.push(element.chance);
  }

  payload.values = values;
  payload.chances = weights;

  const response = await patchUpdateFortuneWheel(payload);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/fortune-wheel');
  }
 };

 const getFortuneWheelRecord = async () => {
  const response = await getFortuneWheelWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  const data = response.data;
  let temp = [];

  for (let i = 0; i < data.value.length; i++) {
   let value = data.value[i];
   let weight = data.chance[i];

   temp.push({
    value: value,
    chance: weight
   });
  }

  setState({
   club: data.Club.name,
   title: data.title
  });

  setWheelValues(temp);
 };

 const handleWheelChange = async (index, value, type) => {
  const regex = /^\d{1,5}(?:\.\d{1,2})?$/;
  if (!isNaN(value)) {
   if (value === '-' || value === '+' || value === 'e' || !regex.test(value)) return;
  }

  const temp = [...wheelValues];

  if (type === 'value') {
   temp[index].value = value;
  } else if (type === 'chance') {
   if (value < 100) {
    temp[index].chance = value;
   } else {
    if (isNaN(value)) {
     temp[index].chance = value;
    } else {
     alert('Win chance should be less than 100');
     return;
    }
   }
  }

  setWheelValues(temp);
 };

 useEffect(() => {
  getFortuneWheelRecord();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="club_label" className="form-label">
        Select the club
       </label>
       <select
        className="form-select"
        name="club"
        id="club_label"
        value={state.club}
        onChange={onChangeFormHandler}
        disabled>
        <option value={state.club}>{state.club}</option>
       </select>
      </div>

      <div className="mb-3">
       <label htmlFor="title_label" className="form-label">
        Title
       </label>

       <input
        type="text"
        className="form-control"
        id="title_label"
        name="title"
        value={state.title}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="mb-3">
       <label htmlFor="platform_label" className="form-label">
        Fortune Wheel
       </label>

       {wheelValues.map((el, index) => {
        return (
         <div className="form-row row" style={{ width: '100%', margin: '8px 0' }}>
          <div className="col-md-3" style={{ width: '40%', padding: 0 }}>
           <label className="form-label" style={{ fontWeight: 'bold' }}>
            Wheel Value {index + 1}
           </label>
           <input
            type="number"
            className="form-control"
            min={0}
            value={wheelValues[index].value}
            onChange={(e) => handleWheelChange(index, parseFloat(e.target.value), 'value')}
           />
          </div>
          <div className="col-md-3" style={{ width: '50%', marginLeft: '10px' }}>
           <label className="form-label" style={{ fontWeight: 'bold' }}>
            Wheel Chance {index + 1}
           </label>
           <input
            type="number"
            className="form-control"
            min={0}
            value={wheelValues[index].chance}
            onChange={(e) => handleWheelChange(index, parseFloat(e.target.value), 'chance')}
           />
          </div>
         </div>
        );
       })}
      </div>

      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Fortune Wheel
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default UpdateForm;
