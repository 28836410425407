import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

// GameSession Transaction Reports

async function getJackpotReport(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/game/jackpot?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// GameSession Transaction Reports

async function getGameTransactionReportWithUsername(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/game/transaction/filter?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// GameSession Transaction Reports

async function postGameTransactionReportFilter(request) {
 try {
  let { data, status } = await axios.post(`${api}/report/game/transaction/filter`, request);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { getJackpotReport, getGameTransactionReportWithUsername, postGameTransactionReportFilter };
