import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAgents, patchAgentAccountStatus, getAgentWithUsername } from '../../api/admin.api';
import { getUserAgentBalance, patchUpdateAgentBalance } from '../../api/balance.api';
import { postAgentPasswordReset } from '../../api/auth.api.js';

import check_account from '../../utils/check_user';

function Table() {
 const user_account = check_account();
 const navigate = useNavigate();

 let isModeratorCashier = user_account.query_account_type.includes('MODERATOR_CASHIER');

 const header_metadata = {
  heading: 'Agents',
  button: true,
  link: '/agent/create',
  text: 'Create Agent'
 };

 let columns = [
  { title: '#', width: '2%' },
  { title: 'Username', width: '12%' },
  { title: 'Balance', width: '10%' },
  { title: 'Recharge', width: '12%' },
  { title: 'Redeem', width: '12%' },
  { title: 'Reset Password', width: '12%' },
  { title: 'Status', width: '10%' },
  { title: 'Last Login', width: '13%' },
  { title: 'Report', width: '18%' },
  { title: 'Manager', width: '12%' }
 ];

 if (isModeratorCashier) {
  columns = [
   { title: '#', width: '2%' },
   { title: 'Username', width: '12%' },
   { title: 'Balance', width: '10%' },
   { title: 'Recharge', width: '12%' },
   { title: 'Redeem', width: '12%' },
   { title: 'Reset Password', width: '12%' },
   { title: 'Status', width: '10%' },
   { title: 'Last Login', width: '13%' },
   { title: 'Manager', width: '12%' }
  ];
 }
 const [rows, setRows] = useState([]);

 const [apiStatus, setApiStatus] = useState(false);
 const [uiConfig, setUiConfig] = useState({
  filter_account_types: []
 });

 const [selectedAgent, setSelectedAgent] = useState({
  id: '',
  name: ''
 });

 const [resetData, setResetData] = useState({
  password: '',
  confirmPassword: ''
 });

 const [accountStatusData, setAccountStatusData] = useState({
  id: '',
  username: '',
  current_status: '',
  changed_to_status: '',
  current_status_color: '',
  changed_to_status_color: ''
 });

 const [userAgentBalance, setUserAgentBalance] = useState({
  user_balance: 0,
  agent_balance: 0
 });

 const [agentUpdateBalance, setAgentUpdateBalance] = useState({
  balance: 0,
  bonus_percent: 0,
  bonus_amount: 0
 });

 const [filter, setFilter] = useState({
  username: '',
  account_type: '',
  prohibited: 'hide'
 });

 const setSelectedAgentHandler = (id, name) => {
  setSelectedAgent({ id, name });
 };

 const accountStatusModelOpen = (index) => {
  let selected = rows[index];
  let user = selected.CreatedUser;

  let changed_to_text = 'ACTIVE';
  let changed_to_color = '#27ae60';
  let current_status_color = '#c0392b';

  if (user.status === 'ACTIVE') {
   changed_to_text = 'DISABLE';
   changed_to_color = '#c0392b';
   current_status_color = '#27ae60';
  }

  setAccountStatusData({
   id: user.id,
   username: user.username,
   current_status: user.status,
   changed_to_status: changed_to_text,
   current_status_color: current_status_color,
   changed_to_status_color: changed_to_color
  });
 };

 const searchWithUsernameHandler = async (e) => {
  setFilter({
   ...filter,
   username: e.target.value
  });

  let response = await getAgentWithUsername(e.target.value);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const modalCloseHandler = () => {
  setResetData({
   password: '',
   confirmPassword: ''
  });

  setUserAgentBalance({
   user_balance: 0,
   agent_balance: 0
  });

  setAgentUpdateBalance({
   balance: 0,
   bonus_percent: 0,
   bonus_amount: 0
  });

  setAccountStatusData({
   id: '',
   username: '',
   current_status: '',
   changed_to_status: '',
   current_status_color: '',
   changed_to_status_color: ''
  });
  setApiStatus(false);
 };

 const setResetPlayerData = (e) => {
  const name = e.target.name;
  const value = e.target.value;

  setResetData({
   ...resetData,
   [name]: value
  });
 };

 const setRechargeRedeemData = (e) => {
  const name = e.target.name;
  const value = e.target.value;

  setAgentUpdateBalance({
   ...agentUpdateBalance,
   [name]: value
  });
 };

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 let selectChangeHandler = async (event) => {
  let name = event.target.name;
  let value = event.target.value;

  setFilter({ ...filter, [name]: value });
 };

 let getAgentsData = async () => {
  let response = await getAgents(filter.prohibited, filter.account_type);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 let getUserAgentBalanceData = async (id, name) => {
  setSelectedAgent({ id, name });
  let response = await getUserAgentBalance(id);
  if (response.status !== 200) errorHandler(response);
  else {
   setUserAgentBalance({
    user_balance: response.data.loggedInUser,
    agent_balance: response.data.updatingUser
   });
  }
 };

 let updatePlayerBalance = async () => {
  let request_body = {
   id: selectedAgent.id,
   balance: agentUpdateBalance.balance,
   bonus_percent: agentUpdateBalance.bonus_percent,
   bonus_amount: agentUpdateBalance.bonus_amount
  };

  let response = await patchUpdateAgentBalance(request_body);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setApiStatus(true);
  }
  setApiStatus(true);
  setAgentUpdateBalance({
   balance: 0,
   bonus_percent: 0,
   bonus_amount: 0
  });
 };

 let resetPasswordHandler = async () => {
  let request_body = {
   id: selectedAgent.id,
   new_password: resetData.password,
   confirm_password: resetData.confirmPassword
  };

  let response = await postAgentPasswordReset(request_body);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setApiStatus(true);
   setResetData({ password: '', confirmPassword: '' });
   setSelectedAgent({ id: '', name: '' });
  }
 };

 let updateAccountStatus = async () => {
  let response = await patchAgentAccountStatus({ id: accountStatusData.id });
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
  }
  setApiStatus(true);
  setAccountStatusData({
   id: '',
   username: '',
   current_status: '',
   changed_to_status: '',
   current_status_color: '',
   changed_to_status_color: ''
  });
 };

 useEffect(() => {
  getAgentsData();

  return () => {
   setApiStatus(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [filter.prohibited, filter.account_type, apiStatus]);

 useEffect(() => {
  let filter_accounts = [];
  let default_account = '-';

  if (user_account.query_account_type === 'SUPER_ADMIN') {
   filter_accounts = [
    { value: '-', label: 'All' },
    { value: 'DISTRIBUTOR', label: 'Distributor' },
    { value: 'SUB_DISTRIBUTOR', label: 'Sub Distributor' },
    { value: 'STORE', label: 'Store' }
   ];
  } else if (user_account.query_account_type === 'DISTRIBUTOR') {
   filter_accounts = [
    { value: '-', label: 'All' },
    { value: 'SUB_DISTRIBUTOR', label: 'Sub Distributor' }
   ];
  } else if (user_account.query_account_type === 'SUB_DISTRIBUTOR') {
   filter_accounts = [
    { value: '-', label: 'All' },
    { value: 'STORE', label: 'Store' }
   ];
  } else if (user_account.query_account_type === 'STORE') {
   filter_accounts = [
    { value: '-', label: 'All' },
    { value: 'MODERATOR_CASHIER', label: 'Moderator Cashier' },
    { value: 'MODERATOR_AUDITOR', label: 'Moderator Auditor' },
    { value: 'MODERATOR_EDITOR', label: 'Moderator Editor' }
   ];
  } else if (
   user_account.query_account_type === 'SUPER_ADMIN_MODERATOR_EDITOR' ||
   user_account.query_account_type === 'SUPER_ADMIN_MODERATOR_CASHIER'
  ) {
   filter_accounts = [
    { value: '-', label: 'All' },
    { value: 'DISTRIBUTOR', label: 'Distributor' },
    { value: 'SUB_DISTRIBUTOR', label: 'Sub Distributor' },
    { value: 'STORE', label: 'Store' }
   ];
  } else if (
   user_account.query_account_type === 'DISTRIBUTOR_MODERATOR_EDITOR' ||
   user_account.query_account_type === 'DISTRIBUTOR_MODERATOR_CASHIER'
  ) {
   filter_accounts = [{ value: 'SUB_DISTRIBUTOR', label: 'Sub Distributor' }];
   default_account = 'SUB_DISTRIBUTOR';
  } else if (
   user_account.query_account_type === 'SUB_DISTRIBUTOR_MODERATOR_EDITOR' ||
   user_account.query_account_type === 'SUB_DISTRIBUTOR_MODERATOR_CASHIER'
  ) {
   filter_accounts = [{ value: 'STORE', label: 'Store' }];
   default_account = 'STORE';
  } else if (
   user_account.query_account_type === 'STORE_MODERATOR_EDITOR' ||
   user_account.query_account_type === 'STORE_MODERATOR_CASHIER'
  ) {
   filter_accounts = [{ value: '-', label: 'All' }];
   default_account = '-';
  }

  setUiConfig({
   filter_account_types: filter_accounts
  });

  setFilter({
   account_type: default_account
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <div
    className="modal fade"
    id="resetPasswordModal"
    tabIndex="-1"
    aria-labelledby="resetModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Reset Password</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       <form>
        <div className="mb-3 row">
         <label htmlFor="password" className="col-sm-2 col-form-label">
          Password
         </label>
         <div className="col-sm-10">
          <input
           type="password"
           className="form-control"
           id="password"
           name="password"
           onChange={setResetPlayerData}
           value={resetData.password}
          />
         </div>
        </div>
        <div className="mb-3 row">
         <label htmlFor="confirmPassword" className="col-sm-2 col-form-label">
          Confirm Password
         </label>
         <div className="col-sm-10">
          <input
           type="password"
           className="form-control"
           id="confirmPassword"
           name="confirmPassword"
           onChange={setResetPlayerData}
           value={resetData.confirmPassword}
          />
         </div>
        </div>
       </form>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={resetPasswordHandler}>
        Reset Password
       </button>
      </div>
     </div>
    </div>
   </div>

   <div
    className="modal fade"
    id="rechargeModal"
    tabIndex="-1"
    aria-labelledby="rechargeModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Recharge</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       <div className="mb-3 row">
        <label htmlFor="agentBalance" className="col-sm-2 col-form-label">
         Agent Balance
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          readOnly
          className="form-control"
          id="agentBalance"
          value={userAgentBalance.agent_balance}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="availableBalance" className="col-sm-2 col-form-label">
         Available Balance
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          readOnly
          className="form-control"
          id="availableBalance"
          value={userAgentBalance.user_balance}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="rechargeAmount" className="col-sm-2 col-form-label">
         Recharge Amount
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="rechargeAmount"
          name="balance"
          value={agentUpdateBalance.balance}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="bonus_percent" className="col-sm-2 col-form-label">
         Bonus Percent
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="bonus_percent"
          name="bonus_percent"
          value={agentUpdateBalance.bonus_percent}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="bonus_amount" className="col-sm-2 col-form-label">
         Bonus Amount
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="bonus_amount"
          name="bonus_amount"
          value={agentUpdateBalance.bonus_amount}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={updatePlayerBalance}>
        Recharge
       </button>
      </div>
     </div>
    </div>
   </div>

   {/* <div
        className="modal fade"
        id="redeemModal"
        tabIndex="-1"
        aria-labelledby="redeemModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Redeem</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label htmlFor="staticAccount" className="col-sm-2 col-form-label">
                  Account
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="staticAccount"
                    value="email@example.com"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemPlayerBalance" className="col-sm-2 col-form-label">
                  Player Balance
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="redeemPlayerBalance"
                    value="320.00"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemAvailableBalance" className="col-sm-2 col-form-label">
                  Available Balance
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="redeemAvailableBalance"
                    value="600.00"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemAmount" className="col-sm-2 col-form-label">
                  Redeem
                </label>
                <div className="col-sm-10">
                  <input type="number" className="form-control" id="redeemAmount" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={modalCloseHandler}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Redeem
              </button>
            </div>
          </div>
        </div>
      </div> */}

   <div
    className="modal fade"
    id="accountStatusModel"
    tabIndex="-1"
    aria-labelledby="accountStatusModelLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Account Status Change</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"></button>
      </div>
      <div className="modal-body">
       <div className="mb-3 row">
        <p>
         You Confirm, You want to change the Account Status of{' '}
         <span style={{ fontWeight: '600' }}>{accountStatusData.username}</span> from{' '}
         <span
          style={{
           fontWeight: '600',
           color: accountStatusData.current_status_color
          }}>
          {accountStatusData.current_status}
         </span>{' '}
         to{' '}
         <span
          style={{
           fontWeight: '600',
           color: accountStatusData.changed_to_status_color
          }}>
          {accountStatusData.changed_to_status}
         </span>{' '}
         ?
        </p>
       </div>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={updateAccountStatus}>
        Confirm
       </button>
      </div>
     </div>
    </div>
   </div>

   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Username:
       </label>
       <input
        type="text"
        className="form-control"
        name="username"
        value={filter.username}
        onChange={searchWithUsernameHandler}
       />
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Account:
       </label>
       <select
        value={filter.account_type}
        className="form-select"
        aria-label="account_type"
        name="account_type"
        onChange={selectChangeHandler}>
        {uiConfig.filter_account_types.map((element, i) => {
         return (
          <option key={i} value={element.value}>
           {element.label}
          </option>
         );
        })}
       </select>
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '50%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Prohibited:
       </label>
       <select
        className="form-select"
        aria-label="prohibited"
        name="prohibited"
        value={filter.prohibited}
        onChange={selectChangeHandler}>
        <option value="hide">Hide</option>
        <option value="show">Show</option>
       </select>
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        let user = el.CreatedUser;
        let status_text_color = user.status !== 'ACTIVE' ? '#e74c3c' : '#27ae60';
        return (
         <tr key={user.id}>
          <td>{i + 1}</td>
          <td>{user.username}</td>
          <td>{user.balance}</td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#rechargeModal"
             style={{
              backgroundColor: '#3498db',
              color: '#fafafa'
             }}
             onClick={() => getUserAgentBalanceData(user.id, user.username)}>
             Recharge
            </button>
           </div>
          </td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#redeemModal"
             style={{
              backgroundColor: '#d35400',
              color: '#fafafa'
             }}
             onClick={() => getUserAgentBalanceData(user.id, user.username)}>
             Redeem
            </button>
           </div>
          </td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#resetPasswordModal"
             style={{
              backgroundColor: '#3498db',
              color: '#fafafa'
             }}
             onClick={() => setSelectedAgentHandler(user.id, user.username)}>
             Reset Password
            </button>
           </div>
          </td>
          <td>
           <div className="col" style={{ fontWeight: '600', color: status_text_color }}>
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#accountStatusModel"
             style={{
              backgroundColor: status_text_color,
              color: '#fafafa'
             }}
             onClick={() => accountStatusModelOpen(i)}>
             {user.status}
            </button>
           </div>
          </td>
          <td>
           <div>2022-07-02 22:19:28</div>
          </td>
          {isModeratorCashier ? null : (
           <td>
            <div className="container">
             <div className="row">
              <div className="col">
               <Link to={`/report/agent/transactions?username=${user.username}`}>
                <button type="button" className="btn btn-primary">
                 Transaction
                </button>
               </Link>
              </div>
             </div>
            </div>
           </td>
          )}

          <td>
           <div className="col">{el.CreatedBy.username}</div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
