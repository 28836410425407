import Sidedrawer from './Sidedrawer';
import './layout.scss';

function Layout(props) {
 return (
  <div className="layout--container">
   <div>
    <Sidedrawer />
    <section className="content--wrapper">{props.children}</section>
   </div>
  </div>
 );
}

export default Layout;
