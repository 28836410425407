const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

const media_content = (content) => {
 let promotion_url = `<p><a href="${content.url}" target="_blank">${content.url} </a> </p>`;
 let resource_file = `<p> Attachment: <br /> <a href="${api}/resources/${content.file_public_url}" targe="_blank">${content.file_name}</a></p>`;
 let text = `<p>${content.title}</p>${content.url !== null ? promotion_url : ''}
 ${content.file_public_url !== null ? resource_file : ''}`;
 console.log(text);
 return text;
};

const offer_content = (content) => {
 let promotion_url = `<p><a href="${content.url}" target="_blank">${content.url} </a> </p>`;
 let resource_file = `<p> Attachment: <br /> <a href="${api}/resources/${content.file_public_url}" targe="_blank">${content.file_name}</a></p>`;
 let text = `${content.title}${content.url !== null ? promotion_url : ''}
 ${content.file_public_url !== null ? resource_file : ''}`;
 return text;
};

export { media_content, offer_content };
