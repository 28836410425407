import { postLogin } from '../../api/auth.api';

class Auth {
 constructor() {
  this.authenticated = false;
  this.api =
   process.env.REACT_APP_ENV === 'production'
    ? `${process.env.REACT_APP_PROD_URL}/api/v1`
    : `${process.env.REACT_APP_DEV_URL}/api/v1`;
 }

 async login(request) {
  const response = await postLogin(request);
  if (response.status !== 200) this.authenticated = false;
  else {
   const { data } = response;

   if (localStorage.getItem('client') !== null) {
    localStorage.removeItem('client');
   }
   localStorage.setItem('client', JSON.stringify(data));
   this.authenticated = true;
  }
  return response;
 }

 logout(cb) {
  localStorage.removeItem('client');
  this.authenticated = false;
  cb();
 }

 isAuthenticated() {
  const token = localStorage.getItem('client');
  if (!token) return false;
  else return true;
 }
}

export default new Auth();
