import { useState } from 'react';
import { Link } from 'react-router-dom';
import './sidedrawer.scss';
import auth from '../Authentication/Auth';

import { FilterRoute } from '../../utils/filterRoute';

function Sidedrawer() {
 let navbar_lists = FilterRoute();

 let sidebar_list = navbar_lists.filter((element) => {
  return element.title !== null;
 });

 const [sidebarClose, setSidebarClose] = useState(false);
 let sidebar_class = 'sidebar close';

 if (!sidebarClose) {
  sidebar_class = 'sidebar';
 }

 const sidebarChangeHandler = () => {
  setSidebarClose(!sidebarClose);
 };

 return (
  <nav className={sidebar_class}>
   <header>
    <div className="image-text">
     {/* <span className="image">Logo Image</span> */}

     <div className="text logo-text">
      <span className="name">{process.env.REACT_APP_DASHBOARD_NAME}</span>
      {/* <span className="profession">Admin panel</span> */}
     </div>
    </div>

    <i className="bx bx-chevron-right toggle" onClick={sidebarChangeHandler}></i>
   </header>
   <div className="menu-bar">
    <div className="menu">
     <ul className="menu-links">
      {sidebar_list.map((element, i) => {
       return (
        <li key={i} className="nav-link">
         <Link to={element.path}>
          <i className={element.icon_class}></i>
          <span className="text nav-text">{element.title}</span>
         </Link>
        </li>
       );
      })}
     </ul>
    </div>
    <div className="bottom-content" style={{ cursor: 'pointer' }}>
     <li
      className="nav-link"
      onClick={() => {
       auth.logout(() => window.location.replace('/auth/login'));
      }}>
      <a href="/auth/login">
       <i className="bx bx-log-out icon"></i>
       <span className="text nav-text">Logout</span>
      </a>
     </li>
    </div>
   </div>
  </nav>
 );
}

export default Sidedrawer;
