import React, { useState, useEffect } from 'react';
import Layout from '../Common/Layout';

import Header from '../Common/Header';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getBonus, deleteBonus } from '../../api/bonus.api';

function Table() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Daily & Weekly Bonus',
  button: true,
  link: '/bonus/create',
  text: 'Create Bonus'
 };

 const columns = [
  { title: '#', width: '3%' },
  { title: 'Bonus', width: '8%' },
  { title: 'Club', width: '20%' },
  { title: 'Bonus Amount', width: '12%' },
  { title: 'Total Missions', width: '12%' },
  { title: 'Action', width: '20%' }
 ];

 const [rows, setRows] = useState([]);
 const [isDeleted, setIsDeleted] = useState(false);

 const [bonusFilter, setBonusFilter] = useState('daily');

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const deleteBonusHandler = async (id) => {
  const response = await deleteBonus(id);
  if (response.status !== 200) errorHandler(response);
  setIsDeleted(true);
 };

 let getBonusList = async () => {
  let response = await getBonus(bonusFilter);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 useEffect(() => {
  getBonusList();

  return () => {
   setIsDeleted(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [bonusFilter, isDeleted]);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper" style={{ padding: '20px 0 20px 15px' }}>
     <div className="mb-6" style={{ width: '100%' }}>
      <div className="d-inline-flex align-items-center" style={{ width: '100%' }}>
       <label
        htmlFor="username"
        className="form-label"
        style={{ paddingRight: '10px', width: '40%' }}>
        Bonus Filter:
       </label>
       <select
        className="form-select"
        aria-label="prohibited"
        name="prohibited"
        value={bonusFilter}
        onChange={(e) => setBonusFilter(e.target.value)}>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
       </select>
      </div>
     </div>
    </div>

    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.bonus_type}</td>
          <td>{el.Club.name}</td>
          <td>{el.bonus_amount}</td>
          <td>{el.missions.length}</td>
          <td>
           <div className="container" style={{ width: '50%' }}>
            <div className="row">
             <div className="col">
              {/* <Link to={`/bonus/update/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link> */}
             </div>
             <div className="col">
              <button type="button" className="btn btn-danger">
               Delete
              </button>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
