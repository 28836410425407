import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

import './conversation.css';

import ChatText from './ChatText';

export default function Conversation(props) {
 let player_user = props.player_user;
 let messages = props.messages !== undefined ? props.messages : [];
 let messageLoader = props.loader;

 let wrapper_style = {};
 let text_style = {};

 if (props.isPlayer) {
  wrapper_style.flexDirection = 'row-reverse';
  text_style.marginRight = '10px';
 }

 return (
  <>
   <div className="conversation-username--container">
    <h4 style={{ textTransform: 'capitalize' }}>{player_user}</h4>
   </div>
   <div className="conversation-text--container">
    <div style={{ position: 'relative' }}>
     <div style={{ position: 'absolute', bottom: '240px', left: '340px' }}>
      <RotatingLines
       strokeColor="#208fff"
       strokeWidth="5"
       animationDuration="0.75"
       width="45"
       visible={messageLoader}
      />
     </div>
    </div>

    {messages.map((element) => {
     return (
      <ChatText
       key={element.entityId}
       isPlayer={element.user_sent}
       text={element.content}
       timestamp={element.timestamp}
       user={player_user}
       isFile={element.is_file}
       file={element.file_path}
      />
     );
    })}
   </div>
  </>
 );
}
