import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';

import { getClubWithID, patchUpdatClub } from '../../api/clubs.api';

function UpdateForm() {
 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Club',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  name: '',
  bonus: '',
  task_title: '',
  task_amount: '',
  task_type: 'spent',
  task_can_complete: ''
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const submitHandler = async () => {
  const response = await patchUpdatClub(state);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/clubs');
  }
 };

 const getClub = async () => {
  const response = await getClubWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else setState({ ...response.data });
 };

 const onChangeHandler = async (element) => {
  const name = element.target.name;
  const value = element.target.value;

  setState({
   ...state,
   [name]: value
  });
 };

 useEffect(() => {
  getClub();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="title_label" className="form-label">
        Name
       </label>
       <input
        type="text"
        className="form-control"
        id="title_label"
        name="name"
        value={state.name}
        disabled
       />
      </div>

      <div className="mb-3">
       <label htmlFor="title_label" className="form-label">
        Club Bonus (%)
       </label>
       <input
        type="number"
        className="form-control"
        id="title_label"
        name="bonus"
        value={state.bonus}
        onChange={onChangeHandler}
       />
      </div>

      <div className="row mb-3" style={{ margin: '30px 0 30px' }}>
       <div className="col-sm" style={{ padding: 0 }}>
        <h4>Rank Task</h4>
       </div>
      </div>

      <div
       className="col-sm"
       style={{ minHeight: '100px', margin: '5px 0 30px', background: '#e9ecef', padding: '10px' }}>
       <div className="form-row" style={{ width: '100%' }}>
        <div className="form-group">
         <label htmlFor="title_label" className="form-label">
          Title
         </label>
         <input
          type="text"
          className="form-control"
          placeholder="Ex: Login for 2 days"
          id="title_label"
          name="task_title"
          value={state.task_title}
          onChange={onChangeHandler}
         />
        </div>

        <div className="form-group" style={{ display: 'flex', gap: '0 20px', margin: '20px 0' }}>
         <div className="col-md-6" style={{ width: '32%' }}>
          <label htmlFor="criteria_type_label" className="form-label">
           Criteria Type
          </label>
          <select
           name="task_type"
           className="form-select"
           id="criteria_type_label"
           value={state.task_type}
           onChange={onChangeHandler}>
           <option value="spent" disabled>
            Spent
           </option>
          </select>
         </div>

         <div className="col-md-6" style={{ width: '32%' }}>
          <label
           htmlFor="criteria_type_value"
           className="form-label"
           style={{ fontWeight: 'bold' }}>
           Amount
          </label>
          <input
           type="number"
           className="form-control"
           id="criteria_type_value"
           placeholder="Ex: 2000"
           name="task_amount"
           min={1}
           value={state.task_amount}
           onChange={onChangeHandler}
          />
         </div>

         <div className="col-md-6" style={{ width: '32%' }}>
          <label htmlFor="can_complete_label" className="form-label">
           Can Complete
          </label>
          <input
           type="number"
           className="form-control"
           id="can_complete_label"
           name="task_can_complete"
           placeholder="Ex: 3"
           value={state.task_can_complete}
           min={1}
           onChange={onChangeHandler}
          />
         </div>
        </div>
       </div>
      </div>

      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Club
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default UpdateForm;
