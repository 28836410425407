import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../Common/Header';
import { getAnnouncementsWithID, patchUpdateAnnouncements } from '../../api/announcement.api';

function CreateForm() {
 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Announcement',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  announcement_text: '',
  announcement_type: '-'
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  const response = await patchUpdateAnnouncements(state);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/announcements');
  }
 };

 const getAnnouncement = async () => {
  const response = await getAnnouncementsWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   setState({
    id: response.data.id,
    announcement_type: response.data.type,
    announcement_text: response.data.announcement
   });
  }
 };

 useEffect(() => {
  getAnnouncement();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="announcement_type_label" className="form-label">
        Announcement Type
       </label>
       <select
        name="announcement_type"
        className="form-select"
        id="announcement_type_label"
        value={state.announcement_type}>
        <option value="-" disabled>
         {state.announcement_type}
        </option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="announcement_textarea_label" className="form-label">
        Announcement Text
       </label>
       <textarea
        className="form-control"
        id="announcement_textarea_label"
        rows="13"
        name="announcement_text"
        onChange={onChangeFormHandler}
        value={state.announcement_text}></textarea>
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Announcements
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
