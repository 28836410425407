import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';

import {
 getJackpotSetting,
 getJackpotSettingWithID,
 patchJackpotSetting
} from '../../api/jackpot.api';

import { getStoreAccounts } from '../../api/admin.api';
import check_account from '../../utils/check_user';

function JP_Setting() {
 const user_account = check_account();
 const isStore = user_account.account_type.toUpperCase() === 'STORE';

 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Jackpot Setting',
  button: false,
  link: '',
  text: ''
 };

 const [user_id, set_user_id] = useState('');

 const [state, setState] = useState({
  id: '',
  account_id: '-',
  grandJP: '',
  grandJP_limit: '',
  majorJP: '',
  majorJP_limit: '',
  minorJP: '',
  minorJP_limit: '',
  miniJP: '',
  miniJP_limit: '',
  grandScore: '',
  majorScore: '',
  minorScore: '',
  miniScore: '',
  miniShareNum: '',
  maxShareNum: '',
  dollarStatus: ''
 });

 const [rows, setRows] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   //   history.push({
   //     pathname: "/auth/login",
   //     state: { details: "Login Expires !! Re-Enter the credentials." }
   //   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  let request_body = {
   user_id,
   setting_id: state.id,
   grandJP: state.grandJP,
   grandJP_limit: state.grandJP_limit,
   majorJP: state.majorJP,
   majorJP_limit: state.majorJP_limit,
   minorJP: state.minorJP,
   minorJP_limit: state.minorJP_limit,
   miniJP: state.miniJP,
   miniJP_limit: state.miniJP_limit,
   grandScore: state.grandScore,
   majorScore: state.majorScore,
   minorScore: state.minorScore,
   miniScore: state.miniScore,
   miniShareNum: state.miniShareNum,
   maxShareNum: state.maxShareNum,
   dollarStatus: state.dollarStatus
  };
  const response = await patchJackpotSetting(request_body);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/game/jackpot/settings');
  }
 };

 const getJackpotData = async () => {
  let response = await getJackpotSetting();
  if (response.status !== 200) errorHandler(response);
  else {
   if (response.data !== null) setState(response.data);
  }
 };

 const getStoresData = async () => {
  let response = await getStoreAccounts();
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const storeJackpotSettingHandler = async (e) => {
  console.log(e.target);
  let id = e.target.value;
  set_user_id(id);
  let response = await getJackpotSettingWithID({ user_id: id });
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(`Jackpot setting fetched.`, {
    position: 'top-right',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setState(response.data);
  }
 };

 useEffect(() => {
  getJackpotData();
  if (!isStore) getStoresData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row row g-3">
      <div className="col-md-12">
       <label htmlFor="player_name_label" className="form-label">
        Account
       </label>
       <select
        id="account_type"
        className="form-select"
        value={state.account_id}
        name="account_id"
        disabled={isStore}
        onChange={storeJackpotSettingHandler}>
        <option value="-">-</option>
        {rows.map((element) => {
         return (
          <option key={element.id} value={element.id}>
           {element.username} ({element.account_type})
          </option>
         );
        })}
       </select>
      </div>

      <p style={{ marginBottom: 0 }}>Accumulate Grand JP</p>
      <div className="col-md-6">
       <label htmlFor="accumulated_grandJP_enabled_label" className="form-label">
        Enabled
       </label>
       <select
        id="accumulated_grandJP_enabled_label"
        className="form-select"
        name="grandJP"
        value={state.grandJP}
        disabled={isStore}
        onChange={onChangeFormHandler}>
        <option value="true">Enabled</option>
        <option value="false">Disabled</option>
       </select>
      </div>
      <div className="col-md-6">
       <label htmlFor="accumulated_grandJP_value_label" className="form-label">
        Value
       </label>
       <input
        type="number"
        className="form-control"
        placeholder="1500"
        name="grandJP_limit"
        disabled={isStore}
        value={state.grandJP_limit}
        onChange={onChangeFormHandler}
       />
      </div>

      <p style={{ marginBottom: 0 }}>Accumulate Major JP</p>
      <div className="col-md-6">
       <label htmlFor="accumulated_majorJP_enabled_label" className="form-label">
        Enabled
       </label>
       <select
        id="accumulated_majorJP_enabled_label"
        className="form-select"
        name="majorJP"
        value={state.majorJP}
        disabled={isStore}
        onChange={onChangeFormHandler}>
        <option value="true">Enabled</option>
        <option value="false">Disabled</option>
       </select>
      </div>
      <div className="col-md-6">
       <label htmlFor="accumulated_majorJP_value_label" className="form-label">
        Value
       </label>
       <input
        type="number"
        className="form-control"
        id="accumulated_majorJP_value_label"
        placeholder="500"
        name="majorJP_limit"
        value={state.majorJP_limit}
        disabled={isStore}
        onChange={onChangeFormHandler}
       />
      </div>

      <p style={{ marginBottom: 0 }}>Accumulate Minor JP</p>
      <div className="col-md-6">
       <label htmlFor="accumulated_minorJP_enabled_label" className="form-label">
        Enabled
       </label>
       <select
        id="accumulated_minorJP_enabled_label"
        className="form-select"
        name="minorJP"
        value={state.minorJP}
        disabled={isStore}
        onChange={onChangeFormHandler}>
        <option value="true">Enabled</option>
        <option value="false">Disabled</option>
       </select>
      </div>
      <div className="col-md-6">
       <label htmlFor="accumulated_minorJP_value_label" className="form-label">
        Value
       </label>
       <input
        type="number"
        className="form-control"
        id="accumulated_minorJP_value_label"
        placeholder="100"
        name="minorJP_limit"
        value={state.minorJP_limit}
        disabled={isStore}
        onChange={onChangeFormHandler}
       />
      </div>

      <p style={{ marginBottom: 0 }}>Accumulate Mini JP</p>
      <div className="col-md-6">
       <label htmlFor="accumulated_miniJP_enabled_label" className="form-label">
        Enabled
       </label>
       <select
        id="accumulated_miniJP_enabled_label"
        className="form-select"
        name="miniJP"
        value={state.miniJP}
        disabled={isStore}
        onChange={onChangeFormHandler}>
        <option value="true">Enabled</option>
        <option value="false">Disabled</option>
       </select>
      </div>
      <div className="col-md-6">
       <label htmlFor="accumulated_miniJP_value_label" className="form-label">
        Value
       </label>
       <input
        type="number"
        className="form-control"
        id="accumulated_miniJP_value_label"
        placeholder="20"
        name="miniJP_limit"
        value={state.miniJP_limit}
        disabled={isStore}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="grand_score_label" className="form-label">
        GrandScore (Range 1500 to 5000)
       </label>
       <input
        type="number"
        className="form-control"
        id="grand_score_label"
        name="grandScore"
        value={state.grandScore}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="major_score_label" className="form-label">
        MajorScore (Range 500 to 2500)
       </label>
       <input
        type="number"
        className="form-control"
        id="major_score_label"
        name="majorScore"
        value={state.majorScore}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="minor_score_label" className="form-label">
        MinorScore (Range 100 to 200)
       </label>
       <input
        type="number"
        className="form-control"
        id="minor_score_label"
        name="minorScore"
        value={state.minorScore}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="mini_score_label" className="form-label">
        MiniScore (Range 20 to 50)
       </label>
       <input
        type="number"
        className="form-control"
        id="mini_score_label"
        name="miniScore"
        value={state.miniScore}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="mini_share_num_label" className="form-label">
        MiniShareNum (Range 1 to 6)
       </label>
       <input
        type="number"
        className="form-control"
        id="mini_share_num_label"
        name="miniShareNum"
        value={state.miniShareNum}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label htmlFor="max_share_num_label" className="form-label">
        MaxShareNum (Range 7 to 10)
       </label>
       <input
        type="number"
        className="form-control"
        id="max_share_num_label"
        name="maxShareNum"
        value={state.maxShareNum}
        onChange={onChangeFormHandler}
       />
      </div>

      <div className="col-md-12">
       <label className="form-check-label" htmlFor="gridRadios1">
        Doller Open Status:
       </label>
       <div onChange={onChangeFormHandler}>
        <span style={{ marginRight: '20px' }}>
         <input
          className="form-check-input"
          type="radio"
          id="radio1"
          name="dollarStatus"
          checked={state.dollarStatus === true || state.dollarStatus === 'open'}
          value={state.dollarStatus}
          onChange={onChangeFormHandler}
         />
         <label className="form-check-label" htmlFor="radio1" style={{ padding: '0 10px' }}>
          Open
         </label>
        </span>
        <input
         className="form-check-input"
         type="radio"
         id="radio2"
         name="dollarStatus"
         checked={state.dollarStatus === false || state.dollarStatus === 'close'}
         value={state.dollarStatus}
         onChange={onChangeFormHandler}
        />
        <label className="form-check-label" htmlFor="radio2" style={{ padding: '0 10px' }}>
         False
        </label>
       </div>
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Jackpot Settings
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default JP_Setting;
