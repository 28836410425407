import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

// Agent - Agent Transaction Reports

async function getAgentToAgentTransactionReport(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/agent/balance?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Agent Transaction Reports

async function getAgentToAgentTransactionReportWithUsername(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/agent/balance/filter?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Agent Transaction Reports

async function postAgentToAgentTransactionReportFilter(request) {
 try {
  let { data, status } = await axios.post(`${api}/report/agent/balance/filter`, request);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Agent Transaction Reports

async function getAgentToAgentTransactionSummaryReport(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/agent/balance/summary?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Agent Transaction Reports

async function getAgentToAgentTransactionSummaryReportWithUsername(query) {
 try {
  let { data, status } = await axios.get(
   `${api}/report/agent/balance/summary/filter?username=${query}`
  );
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postAgentToAgentTransactionSummaryReportFilter(request) {
 try {
  let { data, status } = await axios.post(`${api}/report/agent/balance/summary/filter`, request);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Player Transaction Reports

async function getAgentToPlayerTransactionReport(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/player/balance?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Player Transaction Reports

async function getAgentToPlayerTransactionReportWithUsername(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/player/balance/filter?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Agent - Player Transaction Reports

async function postAgentToPlayerTransactionReportFilter(request) {
 try {
  let { data, status } = await axios.post(`${api}/report/player/balance/filter`, request);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Player GamePlay Report

async function getGameTransactionReport(query) {}

// Player GamePlay Report

async function getGameTransactionReportWithUsername(query) {}

// Player GamePlay Report

async function postGameTransactionReportWithFilter(request) {}

export {
 getAgentToAgentTransactionReport,
 getAgentToAgentTransactionReportWithUsername,
 postAgentToAgentTransactionReportFilter,
 getAgentToAgentTransactionSummaryReport,
 getAgentToAgentTransactionSummaryReportWithUsername,
 postAgentToAgentTransactionSummaryReportFilter,
 getAgentToPlayerTransactionReport,
 getAgentToPlayerTransactionReportWithUsername,
 postAgentToPlayerTransactionReportFilter,
 getGameTransactionReport,
 getGameTransactionReportWithUsername,
 postGameTransactionReportWithFilter
};
