import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Layout from '../../Components/Common/Layout';
import Header from '../Common/Header';

import { getDashboardAssetsWithID, patchDashboardAssets } from '../../api/assets.api';

function CreateForm() {
 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Dashboard Asset',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  platform: 'android'
 });

 const [files, setFiles] = useState({});

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const fileChangeHandler = (event) => {
  setFiles(event.target.files);
 };

 const submitHandler = async () => {
  let form = new FormData();

  form.append('id', params.id);
  form.append('file', files[0]);
  form.append('platform', state.platform);

  const response = await patchDashboardAssets(form);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/game/dashboard/assets');
  }
 };

 const getDashboardAsset = async () => {
  const response = await getDashboardAssetsWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   setState({
    platform: response.data.platform,
    path: response.data.path,
    file: response.file
   });
  }
 };

 useEffect(() => {
  getDashboardAsset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="platform_label" className="form-label">
        Platform
       </label>
       <select
        name="platform"
        className="form-select"
        id="platform_label"
        value={state.platform}
        onChange={onChangeFormHandler}>
        <option value="android">ANDROID</option>
        <option value="ios">IOS</option>
        <option value="web">WEB</option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="assetFile" className="form-label">
        Upload the assets
       </label>
       <input className="form-control" type="file" id="assetFile" onChange={fileChangeHandler} />
       {state.path !== null ? (
        <div style={{ marginTop: '20px' }}>
         <h4 style={{ paddingBottom: '5px' }}>Existing Assets</h4>
         <a style={{ textDecoration: 'underline' }} href={`${state.path}`}>
          {state.file}
         </a>
        </div>
       ) : null}
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Asset
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
