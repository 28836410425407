import { intersection } from 'lodash';
import { PrivateRouteConfig } from '../config/PrivateRouteConfig';

import auth from '../Components/Authentication/Auth';

export function isArrayWithLength(arr) {
 return Array.isArray(arr) && arr.length;
}

/**
 * Filter routes according to role
 * @param routes -IRoutes
 */
export function FilterRoute() {
 const isLoggedin = auth.isAuthenticated();
 let routes = [];
 let roles = [];

 if (isLoggedin) {
  routes = PrivateRouteConfig;
  let client = localStorage.getItem('client');

  if (client !== null) {
   client = JSON.parse(client);
   roles = [client.type];
  }
 }

 return routes.filter(({ permission }) => {
  if (!permission) return true;
  else if (!isArrayWithLength(permission)) return true;
  else return intersection(permission, roles).length;
 });
}
