import jwt_decode from 'jwt-decode';

function check_account() {
 let meta_data = JSON.parse(localStorage.getItem('client'));

 let data = { query_account_type: '', account_type: '' };
 let access_token = null;
 if (meta_data !== null) {
  access_token = meta_data.access_token;
 } else {
  return data;
 }

 let decoded = null;

 if (access_token !== null) {
  decoded = jwt_decode(access_token);
 }

 data = { query_account_type: meta_data.type, account_type: decoded.account };

 return data;
}

export default check_account;
