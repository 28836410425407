import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MapAllowedRoutes from './config/MapAllowedRoutes';
import { FilterRoute } from './utils/filterRoute';

function App() {
 let allowedRoutes = FilterRoute();
 return (
  <Router>
   <ToastContainer />
   <MapAllowedRoutes routes={allowedRoutes} />
  </Router>
 );
}

export default App;
