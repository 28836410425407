import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getMediaLinks() {
 try {
  let { data, status } = await axios.get(`${api}/media-links`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getMediaLinksWithID(query) {
 try {
  let { data, status } = await axios.get(`${api}/media-links/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateMediaLinks(request) {
 try {
  let { data, status } = await axios.post(`${api}/media-links`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdateMediaLinks(request) {
 try {
  let { data, status } = await axios.patch(`${api}/media-links`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function deleteMediaLinks(query) {
 try {
  let { data, status } = await axios.delete(`${api}/media-links/${query}`);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getMediaLinks,
 getMediaLinksWithID,
 postCreateMediaLinks,
 patchUpdateMediaLinks,
 deleteMediaLinks
};
