import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getModerators(prohibited, account_type) {
 try {
  const { data, status } = await axios.get(
   `${api}/moderators?prohibited=${prohibited}&account_type=${account_type}`
  );
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateModerator(request) {
 try {
  const { data, status } = await axios.post(`${api}/moderators/create`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getModeratorWithUsername(query) {
 try {
  let response = await axios.get(`${api}/search/moderator?username=${query}`);
  return { data: response.data.data, status: response.status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { getModerators, postCreateModerator, getModeratorWithUsername };
