import { Role } from './Roles';

import Dashboard from '../Components/Homepage/Main';

import Agents from '../Components/Agents/Table';
import AgentCreate from '../Components/Agents/CreateAgent';

import Moderator from '../Components/Moderator/Table';
import ModeratorCreate from '../Components/Moderator/CreateModerator';

import Players from '../Components/Players/Table';
import PlayerCreate from '../Components/Players/CreatePlayer';
import PlayerUpdate from '../Components/Players/UpdatePlayer';

import Games from '../Components/Game/Table';
import GameCreate from '../Components/Game/CreateGame';
import GameUpdate from '../Components/Game/UpdateGame';

import GameAsset from '../Components/GameAssets/Table';
import GameCreateAsset from '../Components/GameAssets/CreateAsset';
import GameUpdateAsset from '../Components/GameAssets/UpdateAsset';

import DashboardGameAsset from '../Components/DashboardAssets/Table';
import DashboardCreateAsset from '../Components/DashboardAssets/CreateAsset';
import DashboardUpdateAsset from '../Components/DashboardAssets/UpdateAsset';

import FishServerAccounts from '../Components/FishServer/Table';
import FishServerAccountCreate from '../Components/FishServer/CreateAccount';
import FishServerAccountUpdate from '../Components/FishServer/UpdateAccount';

import GamePlaySessionReport from '../Components/Reports/GamePlaySessionReport';

import AgentToAgentReport from '../Components/Reports/AgentToAgentReport';
import AgentSummaryReport from '../Components/Reports/AgentSummaryReport';

import AgentToPlayerReport from '../Components/Reports/AgentToPlayerReport';

import GameReqResReport from '../Components/Auditlogs/GameReqRes';

import JackpotReport from '../Components/Reports/JackpotReport';
import DailySpinReport from '../Components/Reports/DailySpinReport';

import JackpotSetting from '../Components/JP_Settings/Main';

import Announcement from '../Components/Announcements/Table';
import AnnouncementCreate from '../Components/Announcements/CreateAnnouncement';
import AnnouncementUpdate from '../Components/Announcements/UpdateAnnouncement';

import Messages from '../Components/Inbox/Main';
import TwoFactorAuthentication from '../Components/2FA/Main';
import Hierarchy from '../Components/Hierarchy/Main';

import MediaLinks from '../Components/MediaLinks/Table';
import MediaLinkCreate from '../Components/MediaLinks/CreateMedia';
import MediaLinkUpdate from '../Components/MediaLinks/UpdateMedia';

import Offers from '../Components/Offers/Table';
import OfferCreate from '../Components/Offers/CreateOffer';
import OfferUpdate from '../Components/Offers/UpdateOffer';

import Clubs from '../Components/Clubs/Table';
import ClubUpdate from '../Components/Clubs/UpdateClub';

import Bonus from '../Components/Bonus/Table';
import BonusCreate from '../Components/Bonus/CreateBonus';

import FortuneWheel from '../Components/FortuneWheel/Table';
import FortuneWheelCreate from '../Components/FortuneWheel/CreateFortuneWheel';
import FortuneWheelUpdate from '../Components/FortuneWheel/UpdateFortuneWheel';

/**
 * Define routes
 */
export const PrivateRouteConfig = [
 {
  component: <Dashboard />,
  path: '/',
  title: 'Dashboard',
  icon_class: 'bx bx-home-alt icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_CASHIER,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.DISTRIBUTOR,
   Role.DISTRIBUTOR_MODERATOR_CASHIER,
   Role.DISTRIBUTOR_MODERATOR_EDITOR,

   Role.SUB_DISTRIBUTOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_CASHIER,
   Role.SUB_DISTRIBUTOR_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_CASHIER,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <Agents />,
  path: '/agents',
  title: 'Agent',
  icon_class: 'bx bxs-user icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_CASHIER,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.DISTRIBUTOR,
   Role.DISTRIBUTOR_MODERATOR_CASHIER,
   Role.DISTRIBUTOR_MODERATOR_EDITOR,

   Role.SUB_DISTRIBUTOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_CASHIER,
   Role.SUB_DISTRIBUTOR_MODERATOR_EDITOR
  ]
 },
 {
  component: <AgentCreate />,
  path: '/agent/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_CASHIER,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.DISTRIBUTOR,
   Role.DISTRIBUTOR_MODERATOR_CASHIER,
   Role.DISTRIBUTOR_MODERATOR_EDITOR,

   Role.SUB_DISTRIBUTOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_CASHIER,
   Role.SUB_DISTRIBUTOR_MODERATOR_EDITOR
  ]
 },
 {
  component: <Moderator />,
  path: '/moderator',
  title: 'Moderator',
  icon_class: 'bx bxs-user icon',
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.DISTRIBUTOR, Role.SUB_DISTRIBUTOR, Role.STORE]
 },
 {
  component: <ModeratorCreate />,
  path: '/moderator/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.DISTRIBUTOR, Role.SUB_DISTRIBUTOR, Role.STORE]
 },
 {
  component: <Players />,
  path: '/players',
  title: 'Players',
  icon_class: 'bx bxs-user icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_CASHIER,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_CASHIER,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <PlayerCreate />,
  path: '/player/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_CASHIER,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_CASHIER,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <Games />,
  path: '/games',
  title: 'Games',
  icon_class: 'bx bx-joystick icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <GameCreate />,
  path: '/game/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <GameUpdate />,
  path: '/game/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <GameAsset />,
  path: '/game/assets',
  title: 'Game Assets',
  icon_class: 'bx bxs-file-blank icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <GameCreateAsset />,
  path: '/game/assets/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <GameUpdateAsset />,
  path: '/game/assets/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <DashboardGameAsset />,
  path: '/game/dashboard/assets',
  title: 'Dashboard Assets',
  icon_class: 'bx bxs-file-blank icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <Clubs />,
  path: '/clubs',
  title: 'Clubs',
  icon_class: 'bx bxs-star icon',
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <ClubUpdate />,
  path: '/club/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <FortuneWheel />,
  path: '/fortune-wheel',
  title: 'Fortune Wheel',
  icon_class: 'bx bx-circle icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <FortuneWheelCreate />,
  path: '/fortune-wheel/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <FortuneWheelUpdate />,
  path: '/fortune-wheel/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <DashboardCreateAsset />,
  path: '/game/dashboard/assets/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <DashboardUpdateAsset />,
  path: '/game/dashboard/assets/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <Bonus />,
  path: '/bonus',
  title: 'Bonus',
  icon_class: 'bx bxs-gift icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <BonusCreate />,
  path: '/bonus/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <Offers />,
  path: '/offers',
  title: 'Offers',
  icon_class: 'bx bxs-offer icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <OfferCreate />,
  path: '/offer/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <OfferUpdate />,
  path: '/offer/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },

 {
  component: <FishServerAccounts />,
  path: '/game/fish-server/accounts',
  title: 'Fish Server Account',
  icon_class: 'bx bxs-user icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <FishServerAccountCreate />,
  path: '/game/fish-server/account/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <FishServerAccountUpdate />,
  path: '/game/fish-server/accounts/update',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.SUPER_ADMIN_MODERATOR_DEVELOPER
  ]
 },
 {
  component: <JackpotSetting />,
  path: '/game/jackpot/settings',
  title: 'Jackpot Settings',
  icon_class: 'bx bx-reset icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <MediaLinks />,
  path: '/media/links',
  title: 'Media Links',
  icon_class: 'bx bx-link icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <MediaLinkCreate />,
  path: '/media/links/create',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <MediaLinkUpdate />,
  path: '/media/links/update/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <GameReqResReport />,
  path: '/report/transaction/audit-logs',
  title: 'Game Req-Res',
  icon_class: 'bx bx-reset icon',
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR, Role.DEVELOPER]
 },
 {
  component: <JackpotReport />,
  path: '/report/jackpot',
  title: 'Jackpot Report',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_AUDITOR,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <DailySpinReport />,
  path: '/report/daily-spin',
  title: 'Daily Spin Report',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_AUDITOR,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <GamePlaySessionReport />,
  path: '/report/game/transactions',
  title: 'Game Report',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <AgentToAgentReport />,
  path: '/report/agent/transactions',
  title: 'Transaction Reports',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_AUDITOR,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.DISTRIBUTOR,
   Role.DISTRIBUTOR_MODERATOR_AUDITOR,
   Role.DISTRIBUTOR_MODERATOR_EDITOR,

   Role.SUB_DISTRIBUTOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_AUDITOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_EDITOR
  ]
 },
 {
  component: <AgentSummaryReport />,
  path: '/report/agent',
  title: 'Reports',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_AUDITOR,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.DISTRIBUTOR,
   Role.DISTRIBUTOR_MODERATOR_AUDITOR,
   Role.DISTRIBUTOR_MODERATOR_EDITOR,

   Role.SUB_DISTRIBUTOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_AUDITOR,
   Role.SUB_DISTRIBUTOR_MODERATOR_EDITOR
  ]
 },
 {
  component: <AgentToPlayerReport />,
  path: '/report/agent-player/transactions',
  title: 'Agent-Player Report',
  icon_class: 'bx bxs-report icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_AUDITOR,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,

   Role.STORE,
   Role.STORE_MODERATOR_AUDITOR,
   Role.STORE_MODERATOR_EDITOR
  ]
 },

 {
  component: <Announcement />,
  path: '/announcements',
  title: 'Announcements',
  icon_class: 'bx bx-pencil icon',
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <AnnouncementCreate />,
  path: '/admin/create/announcement',
  title: null,
  icon_class: null,
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <AnnouncementUpdate />,
  path: '/admin/update/announcement/:id',
  title: null,
  icon_class: null,
  exact: true,
  permission: [Role.SUPER_ADMIN, Role.SUPER_ADMIN_MODERATOR_EDITOR]
 },
 {
  component: <Messages />,
  path: '/admin/inbox',
  title: 'Messages',
  icon_class: 'bx bxs-chat icon',
  exact: true,
  permission: [
   Role.SUPER_ADMIN,
   Role.SUPER_ADMIN_MODERATOR_EDITOR,
   Role.STORE,
   Role.STORE_MODERATOR_EDITOR
  ]
 },
 {
  component: <TwoFactorAuthentication />,
  path: '/2FA',
  title: '2FA Setting',
  icon_class: 'bx bxs-cog icon',
  exact: true,
  permission: []
 }
 //  {
 //   component: <Hierarchy />,
 //   path: '/agent/hierarchy-data',
 //   title: 'Hierarchy',
 //   icon_class: 'bx bxs-cog icon',
 //   exact: true,
 //   permission: []
 //  }
];
