import React, { useEffect, useState } from 'react';
import Layout from '../Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';
import { postCreateModerator } from '../../api/moderator.api';

import check_account from '../../utils/check_user';

function CreateForm() {
 const user_account = check_account();
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Create Moderator',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  account_name: '',
  username: '',
  timezone: 'EST',
  password: '',
  account_type: 'AUDITOR',
  perTransLimit: 0,
  perDayLimit: 0
 });

 const [uiConfig, setUiConfig] = useState({
  account_types: []
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;

  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  let request = {
   ...state,
   perTransLimit: state.account_type === 'AUDITOR' ? 0 : parseFloat(state.perTransLimit),
   perDayLimit: state.account_type === 'AUDITOR' ? 0 : parseFloat(state.perDayLimit)
  };

  const response = await postCreateModerator(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/moderator');
  }
 };

 useEffect(() => {
  let accounts = ['AUDITOR', 'CASHIER', 'EDITOR'];

  if (user_account.query_account_type === 'SUPER_ADMIN') {
   accounts.push('DEVELOPER');
  }

  setUiConfig({
   ...uiConfig,
   account_types: accounts
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row row g-4" style={{ width: '90%' }}>
      <div className="col-md-3">
       <label htmlFor="admin_account_name_label" className="form-label">
        Account Name
       </label>
       <input
        type="text"
        className="form-control"
        id="admin_account_name_label"
        name="account_name"
        value={state.account_name}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-3">
       <label htmlFor="admin_username_label" className="form-label">
        Username
       </label>
       <input
        type="text"
        className="form-control"
        id="admin_username_label"
        name="username"
        value={state.username}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-3">
       <label htmlFor="admin_password_label" className="form-label">
        Password
       </label>
       <input
        type="password"
        className="form-control"
        id="admin_password_label"
        name="password"
        value={state.password}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="col-md-3">
       <label htmlFor="admin_timezone_label" className="form-label">
        Timezone
       </label>
       <select
        className="form-select"
        id="admin_timezone_label"
        name="timezone"
        onChange={onChangeFormHandler}
        value={state.timezone}>
        <option value="CHINA">CHINA</option>
        <option value="HST">HST</option>
        <option value="AKST">AKST</option>
        <option value="PST">PST</option>
        <option value="MST">MST</option>
        <option value="CST">CST</option>
        <option value="EST">EST</option>
        <option value="HST_SUM">HST SUM</option>
        <option value="AKST_SUM">AKST SUM</option>
        <option value="PST_SUM">PST SUM</option>
        <option value="MST_SUM">MST SUM</option>
        <option value="CST_SUM">CST SUM</option>
        <option value="EST_SUM">EST SUM</option>
       </select>
      </div>
      <div className="col-md-3">
       <label htmlFor="admin_account_type_label" className="form-label">
        Account
       </label>
       <select
        className="form-select"
        id="admin_account_type_label"
        name="account_type"
        value={state.account_type}
        onChange={onChangeFormHandler}>
        {uiConfig.account_types.map((element, i) => {
         return (
          <option key={i} value={element}>
           {element}
          </option>
         );
        })}
       </select>
      </div>
     </div>

     {state.account_type !== 'AUDITOR' && state.account_type !== 'DEVELOPER' ? (
      <div className="form-row row g-4" style={{ width: '90%' }}>
       <h5>Moderator Limit</h5>
       <div className="col-md-3">
        <label htmlFor="perTransLimitLabel" className="form-label">
         Per Transaction Limit
        </label>
        <input
         type="number"
         className="form-control"
         id="perTransLimitLabel"
         name="perTransLimit"
         value={state.perTransLimit}
         onChange={onChangeFormHandler}
        />
       </div>
       <div className="col-md-3">
        <label htmlFor="perDayLimitLabel" className="form-label">
         Per Day Limit
        </label>
        <input
         type="number"
         className="form-control"
         id="perDayLimitLabel"
         name="perDayLimit"
         value={state.perDayLimit}
         onChange={onChangeFormHandler}
        />
       </div>
      </div>
     ) : null}

     <div className="form-row row g-4" style={{ width: '90%' }}>
      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Create Moderator
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
