import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';

import { getOfferWithID, patchUpdateOffer } from '../../api/offers.api';

function UpdateForm() {
 const api =
  process.env.REACT_APP_ENV === 'production'
   ? `${process.env.REACT_APP_PROD_URL}/api/v1`
   : `${process.env.REACT_APP_DEV_URL}/api/v1`;

 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Offer',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  title: '',
  value: '',
  type: '-',
  bonus_percent_fix: '',
  description: ''
 });

 const [files, setFiles] = useState({});

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const fileChangeHandler = (event) => {
  setFiles(event.target.files);
 };

 const submitHandler = async () => {
  let form = new FormData();

  form.append('image', files[0]);
  form.append('id', state.id);
  form.append('title', state.title);
  form.append('type', state.type);
  form.append('value', state.value);
  form.append('bonus_percent_fix', state.bonus_percent_fix);
  form.append('description', state.description);

  const response = await patchUpdateOffer(form);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/offers');
  }
 };

 const getOffer = async () => {
  const response = await getOfferWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   const data = response.data;

   setState({
    id: data.id,
    title: data.title,
    value: data.value,
    image_path: data.image_path,
    bonus_percent_fix: data.bonus_percent_fix,
    type: data.type.toLowerCase(),
    description: data.description
   });
  }
 };

 useEffect(() => {
  getOffer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="title_label" className="form-label">
        Title
       </label>
       <input
        type="text"
        className="form-control"
        id="title_label"
        name="title"
        value={state.title}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="value_label" className="form-label">
        Value
       </label>
       <input
        type="number"
        className="form-control"
        id="value_label"
        name="value"
        value={state.value}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="platform_label" className="form-label">
        Type
       </label>
       <select
        name="type"
        className="form-select"
        id="type_label"
        value={state.type}
        onChange={onChangeFormHandler}>
        <option value="-" disabled>
         -
        </option>
        <option value="fix">Fix</option>
        <option value="percent">Percent</option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="bonus_percent_fix_label" className="form-label">
        Bonus (Percent/Fix)
       </label>
       <input
        type="number"
        className="form-control"
        id="bonus_percent_fix_label"
        name="bonus_percent_fix"
        value={state.bonus_percent_fix}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="description_textarea" className="form-label">
        Description
       </label>
       <textarea
        className="form-control"
        id="description_textarea"
        rows="3"
        name="description"
        onChange={onChangeFormHandler}
        value={state.description}></textarea>
      </div>

      <div className="mb-3">
       <label htmlFor="offerImage" className="form-label">
        Upload the Image
       </label>
       <input className="form-control" type="file" id="offerImage" onChange={fileChangeHandler} />
       {state.image_path !== null ? (
        <div style={{ marginTop: '20px' }}>
         <h4 style={{ paddingBottom: '5px' }}>Existing Image</h4>
         <a
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noreferrer"
          href={`${api}${state.image_path}`}>
          View Image
         </a>
        </div>
       ) : null}
      </div>

      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Offer
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default UpdateForm;
