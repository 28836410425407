import React from 'react';

import './conversation.css';

export default function ChatText(props) {
 let text = props.text;
 let user = props.user;

 let wrapper_style = {};
 let text_style = {};

 if (!props.isPlayer) {
  wrapper_style.flexDirection = 'row-reverse';
  text_style.marginRight = '10px';
  user = 'Admin';
 }

 if (props.isFile) text = props.file;

 return (
  <>
   <div className="conversation-text--wrapper" style={wrapper_style}>
    <div className="conversation-text" style={text_style}>
     <h5 className="conversation-username">{user}</h5>
     {props.isFile ? (
      <img crossOrigin="anonymous" src={text} alt="screenshot" width={200} />
     ) : (
      <p className="conversation-message" dangerouslySetInnerHTML={{ __html: text }}></p>
     )}
     <p className="conversation-timestamp">{`${new Date(props.timestamp).toLocaleString()}`}</p>
    </div>
   </div>
  </>
 );
}
