import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getPlayers, patchPlayerAccountStatus, getPlayerWithUsername } from '../../api/players.api';
import { getAgentPlayerBalance, patchUpdatePlayerBalance } from '../../api/balance.api';
import { postPlayerPasswordReset } from '../../api/auth.api.js';

function Table() {
 const navigate = useNavigate();

 let header_metadata = {
  heading: 'Players',
  button: true,
  link: '/player/create',
  text: 'Create Player'
 };

 const columns = [
  { title: '#', width: '2%' },
  { title: 'Username', width: '12%' },
  { title: 'Cust ID', width: '8%' },
  { title: 'Balance', width: '8%' },
  { title: 'Recharge', width: '8%' },
  { title: 'Redeem', width: '8%' },
  { title: 'Reset Password', width: '14%' },
  { title: 'Status', width: '10%' },
  { title: 'Last Login', width: '9%' },
  { title: 'Report', width: '25%' },
  { title: 'Manager', width: '12%' }
 ];

 const [rows, setRows] = useState([]);

 const [apiStatus, setApiStatus] = useState(false);

 const [selectedPlayer, setSelectedPlayer] = useState({
  id: '',
  name: ''
 });

 const [resetData, setResetData] = useState({
  password: '',
  confirmPassword: ''
 });

 const [userPlayerBalance, setUserPlayerBalance] = useState({
  user_balance: 0,
  player_balance: 0,
  club_bonus: '0'
 });

 const [accountStatusData, setAccountStatusData] = useState({
  id: '',
  username: '',
  current_status: '',
  changed_to_status: '',
  current_status_color: '',
  changed_to_status_color: ''
 });

 const [playerUpdateBalance, setPlayerUpdateBalance] = useState({
  balance: 0,
  bonus_percent: 0,
  bonus_amount: 0
 });

 const [filter, setFilter] = useState({
  username: '',
  prohibited: 'false'
 });

 const filterChangeHandler = async (event) => {
  let name = event.target.name;
  let value = event.target.value;
  setFilter({ ...filter, [name]: value });

  if (name === 'username') {
   let response = await getPlayerWithUsername(value, filter.prohibited);
   if (response.status !== 200) errorHandler(response);
   else setRows(response.data);
  }
 };

 const setSelectedPlayerHandler = (id, name) => {
  setSelectedPlayer({ id, name });
 };

 const accountStatusModelOpen = (index) => {
  let selected = rows[index];

  let changed_to_text = 'ACTIVE';
  let changed_to_color = '#27ae60';
  let current_status_color = '#c0392b';

  if (selected.status === 'ACTIVE') {
   changed_to_text = 'DISABLE';
   changed_to_color = '#c0392b';
   current_status_color = '#27ae60';
  }

  setAccountStatusData({
   id: selected.id,
   username: selected.username,
   current_status: selected.status,
   changed_to_status: changed_to_text,
   current_status_color: current_status_color,
   changed_to_status_color: changed_to_color
  });
 };

 const modalCloseHandler = () => {
  setResetData({
   password: '',
   confirmPassword: ''
  });

  setUserPlayerBalance({
   user_balance: 0,
   player_balance: 0
  });

  setPlayerUpdateBalance({
   balance: 0,
   bonus_percent: 0,
   bonus_amount: 0
  });

  setAccountStatusData({
   id: '',
   username: '',
   current_status: '',
   changed_to_status: '',
   current_status_color: '',
   changed_to_status_color: ''
  });
 };

 const setResetPlayerData = (e) => {
  const name = e.target.name;
  const value = e.target.value;

  setResetData({
   ...resetData,
   [name]: value
  });
 };

 const setRechargeRedeemData = (e) => {
  const name = e.target.name;
  const value = e.target.value;

  setPlayerUpdateBalance({
   ...playerUpdateBalance,
   [name]: value
  });
 };

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 let getPlayerData = async () => {
  let response = await getPlayers(filter.prohibited);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 let getAgentPlayerBalanceData = async (id, name) => {
  setSelectedPlayer({ id, name });
  let response = await getAgentPlayerBalance(id);
  if (response.status !== 200) errorHandler(response);
  else {
   setUserPlayerBalance({
    user_balance: response.data.loggedInUser,
    player_balance: response.data.updatePlayer,
    club_bonus: response.data.club_bonus
   });
  }
 };

 let updatePlayerBalance = async () => {
  let request_body = {
   id: selectedPlayer.id,
   balance: playerUpdateBalance.balance,
   bonus_percent: playerUpdateBalance.bonus_percent,
   bonus_amount: playerUpdateBalance.bonus_amount
  };

  let response = await patchUpdatePlayerBalance(request_body);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
  }
  setPlayerUpdateBalance({
   balance: 0,
   bonus_percent: 0,
   bonus_amount: 0
  });
  setApiStatus(true);
 };

 let resetPasswordHandler = async () => {
  let request_body = {
   id: selectedPlayer.id,
   new_password: resetData.password,
   confirm_password: resetData.confirmPassword
  };

  let response = await postPlayerPasswordReset(request_body);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
  }
 };

 let updateAccountStatus = async () => {
  let response = await patchPlayerAccountStatus({ id: accountStatusData.id });
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
  }
  setApiStatus(true);
  setAccountStatusData({
   id: '',
   username: '',
   current_status: '',
   changed_to_status: '',
   current_status_color: '',
   changed_to_status_color: ''
  });
 };

 useEffect(() => {
  getPlayerData();

  return () => {
   setApiStatus(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [filter.prohibited, apiStatus]);

 return (
  <Layout>
   <div
    className="modal fade"
    id="resetPasswordModal"
    tabIndex="-1"
    aria-labelledby="resetModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Reset Password</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       {/* <div className="mb-3 row">
                <label htmlFor="username_reset" className="col-sm-2 col-form-label">
                  Account
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="username_reset"
                    value={selectedPlayer.name}
                  />
                </div>
              </div> */}
       <form>
        <div className="mb-3 row">
         <label htmlFor="password" className="col-sm-2 col-form-label">
          Password
         </label>
         <div className="col-sm-10">
          <input
           type="password"
           className="form-control"
           id="password"
           name="password"
           onChange={setResetPlayerData}
           value={resetData.password}
          />
         </div>
        </div>
        <div className="mb-3 row">
         <label htmlFor="confirmPassword" className="col-sm-2 col-form-label">
          Confirm Password
         </label>
         <div className="col-sm-10">
          <input
           type="password"
           className="form-control"
           id="confirmPassword"
           name="confirmPassword"
           onChange={setResetPlayerData}
           value={resetData.confirmPassword}
          />
         </div>
        </div>
       </form>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={resetPasswordHandler}>
        Reset Password
       </button>
      </div>
     </div>
    </div>
   </div>

   <div
    className="modal fade"
    id="rechargeModal"
    tabIndex="-1"
    aria-labelledby="rechargeModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Recharge</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       {/* <div className="mb-3 row">
                <label htmlFor="username_recharge" className="col-sm-2 col-form-label">
                  Account
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="username_recharge"
                    value={selectedPlayer.name}
                  />
                </div>
              </div> */}

       <div className="mb-3 row">
        <label htmlFor="playerBalance" className="col-sm-2 col-form-label">
         Player Balance
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          readOnly
          className="form-control"
          id="playerBalance"
          value={userPlayerBalance.player_balance}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="availableBalance" className="col-sm-2 col-form-label">
         Available Balance
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          readOnly
          className="form-control"
          id="availableBalance"
          value={userPlayerBalance.user_balance}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="rechargeAmount" className="col-sm-2 col-form-label">
         Club Bonus
        </label>
        <div className="col-sm-10">
         <input
          type="text"
          className="form-control"
          id="rechargeAmount"
          name="balance"
          value={userPlayerBalance.club_bonus}
          disabled
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="rechargeAmount" className="col-sm-2 col-form-label">
         Recharge Amount
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="rechargeAmount"
          name="balance"
          value={playerUpdateBalance.balance}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="bonus_percent" className="col-sm-2 col-form-label">
         Bonus Percent
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="bonus_percent"
          name="bonus_percent"
          value={playerUpdateBalance.bonus_percent}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>

       <div className="mb-3 row">
        <label htmlFor="bonus_amount" className="col-sm-2 col-form-label">
         Bonus Amount
        </label>
        <div className="col-sm-10">
         <input
          type="number"
          className="form-control"
          id="bonus_amount"
          name="bonus_amount"
          value={playerUpdateBalance.bonus_amount}
          onChange={setRechargeRedeemData}
         />
        </div>
       </div>

       {/* <div className="mb-3">
                <label htmlFor="example_textarea" className="form-label">
                  Remark
                </label>
                <textarea
                  className="form-control"
                  id="example_textarea"
                  rows="2"
                  name="remark"
                  onChange={setRechargeRedeemData}
                  value={playerUpdateBalance.remark}
                ></textarea>
              </div> */}
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={updatePlayerBalance}>
        Recharge
       </button>
      </div>
     </div>
    </div>
   </div>

   {/* <div
        className="modal fade"
        id="redeemModal"
        tabIndex="-1"
        aria-labelledby="redeemModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Redeem</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label htmlFor="staticAccount" className="col-sm-2 col-form-label">
                  Account
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="staticAccount"
                    value="email@example.com"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemPlayerBalance" className="col-sm-2 col-form-label">
                  Player Balance
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="redeemPlayerBalance"
                    value="320.00"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemAvailableBalance" className="col-sm-2 col-form-label">
                  Available Balance
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="redeemAvailableBalance"
                    value="600.00"
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="redeemAmount" className="col-sm-2 col-form-label">
                  Redeem
                </label>
                <div className="col-sm-10">
                  <input type="number" className="form-control" id="redeemAmount" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={modalCloseHandler}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Redeem
              </button>
            </div>
          </div>
        </div>
      </div> */}

   <div
    className="modal fade"
    id="accountStatusModel"
    tabIndex="-1"
    aria-labelledby="accountStatusModelLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Account Status Change</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"></button>
      </div>
      <div className="modal-body">
       <div className="mb-3 row">
        <p>
         You Confirm, You want to change the Account Status of{' '}
         <span style={{ fontWeight: '600' }}>{accountStatusData.username}</span> from{' '}
         <span
          style={{
           fontWeight: '600',
           color: accountStatusData.current_status_color
          }}>
          {accountStatusData.current_status}
         </span>{' '}
         to{' '}
         <span
          style={{
           fontWeight: '600',
           color: accountStatusData.changed_to_status_color
          }}>
          {accountStatusData.changed_to_status}
         </span>{' '}
         ?
        </p>
       </div>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={updateAccountStatus}>
        Confirm
       </button>
      </div>
     </div>
    </div>
   </div>

   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Username:
       </label>
       <input
        type="text"
        className="form-control"
        name="username"
        value={filter.username}
        onChange={filterChangeHandler}
       />
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '50%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Prohibited:
       </label>
       <select
        className="form-select"
        aria-label="prohibited"
        name="prohibited"
        value={filter.prohibited}
        onChange={filterChangeHandler}>
        <option value="false">Hide</option>
        <option value="true">Show</option>
       </select>
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        let status_text_color = el.status !== 'ACTIVE' ? '#e74c3c' : '#27ae60';
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.username}</td>
          <td>{el.system_id}</td>
          <td>{el.balance}</td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#rechargeModal"
             style={{
              backgroundColor: '#3498db',
              color: '#fafafa'
             }}
             onClick={() => getAgentPlayerBalanceData(el.id, el.username)}>
             Recharge
            </button>
           </div>
          </td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#redeemModal"
             style={{
              backgroundColor: '#d35400',
              color: '#fafafa'
             }}
             onClick={() => getAgentPlayerBalanceData(el.id, el.username)}>
             Redeem
            </button>
           </div>
          </td>
          <td>
           <div className="col">
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#resetPasswordModal"
             style={{
              backgroundColor: '#3498db',
              color: '#fafafa'
             }}
             onClick={() => setSelectedPlayerHandler(el.id, el.username)}>
             Reset Password
            </button>
           </div>
          </td>
          <td>
           <div className="col" style={{ fontWeight: '600', color: status_text_color }}>
            <button
             type="button"
             className="btn"
             data-bs-toggle="modal"
             data-bs-target="#accountStatusModel"
             style={{
              backgroundColor: status_text_color,
              color: '#fafafa'
             }}
             onClick={() => accountStatusModelOpen(i)}>
             {el.status}
            </button>
           </div>
          </td>
          <td>
           <div>2022-07-02 22:19:28</div>
          </td>
          <td>
           <div className="container">
            <div className="row">
             <div className="col">
              <Link to={`/report/agent-player/transactions?username=${el.username}`}>
               <button type="button" className="btn btn-primary">
                Transaction
               </button>
              </Link>
             </div>
             <div className="col">
              <Link to={`/report/game/transactions?username=${el.username}`}>
               <button type="button" className="btn btn-primary">
                Game
               </button>
              </Link>
             </div>
            </div>
           </div>
          </td>
          <td>
           <div className="col">{el.CreatedBy.username}</div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
