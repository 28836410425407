import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getAgentPlayerBalance(query) {
 try {
  let { data, status } = await axios.get(`${api}/player/update/balance/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdatePlayerBalance(request) {
 try {
  let { data, status } = await axios.patch(`${api}/player/update/balance`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getUserAgentBalance(query) {
 try {
  let { data, status } = await axios.get(`${api}/agent/update/balance/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdateAgentBalance(request) {
 try {
  let { data, status } = await axios.patch(`${api}/agent/update/balance`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getAgentPlayerBalance,
 patchUpdatePlayerBalance,
 getUserAgentBalance,
 patchUpdateAgentBalance
};
