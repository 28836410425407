import React, { useState } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../Common/Header';
import { getGameWithID, patchUpdateGame } from '../../api/games.api';
import { useEffect } from 'react';

function CreateForm() {
 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Game',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  game_name: '',
  description: ''
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async () => {
  const response = await patchUpdateGame(state);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/games');
  }
 };

 const getGame = async () => {
  const response = await getGameWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   setState({
    id: response.data.id,
    game_name: response.data.name,
    description: response.data.description
   });
  }
 };

 useEffect(() => {
  getGame();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="game_name_label" className="form-label">
        Game Name
       </label>
       <input
        type="text"
        className="form-control"
        id="game_name_label"
        placeholder="Happy Duck"
        name="game_name"
        value={state.game_name}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="description_textarea" className="form-label">
        Description
       </label>
       <textarea
        className="form-control"
        id="description_textarea"
        rows="3"
        name="description"
        onChange={onChangeFormHandler}
        value={state.description}></textarea>
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Game
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
