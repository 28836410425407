import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Layout from '../../Components/Common/Layout';
import Header from '../Common/Header';

import {
 getAgentToPlayerTransactionReport,
 getAgentToPlayerTransactionReportWithUsername,
 postAgentToPlayerTransactionReportFilter
} from '../../api/report.api';

function Table() {
 const navigate = useNavigate();

 const [query] = useSearchParams();
 const username = query.get('username');

 const header_metadata = {
  heading: 'Agent To Player Report',
  button: false,
  link: '',
  text: ''
 };

 const columns = [
  { title: '#', width: '2%' },
  { title: 'DateTime', width: '10%' },
  { title: 'Manager', width: '10%' },
  { title: 'Level', width: '10%' },
  { title: 'Username', width: '10%' },
  { title: 'Recharged', width: '10%' },
  { title: 'Bonus(%)', width: '10%' },
  { title: 'Bonus Amount', width: '10%' },
  { title: 'Total Recharged', width: '10%' },
  { title: 'Redeem', width: '10%' },
  { title: 'Income', width: '10%' }
 ];

 const [rows, setRows] = useState([]);

 const [reportFilter, setReportFiler] = useState({
  username: '',
  from: '',
  to: ''
 });

 const getAgentPlayerReport = async (query1) => {
  let response = await getAgentToPlayerTransactionReport(query1);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const onChangeHandler = async (e) => {
  const name = e.target.name;
  const value = e.target.value;

  setReportFiler({
   ...reportFilter,
   [name]: value
  });

  if (name === 'username') {
   const response = await getAgentToPlayerTransactionReportWithUsername(value);
   if (response.status !== 200) errorHandler(response);
   setRows(response.data);
  }
 };

 const reportFilterHandler = async (e) => {
  const request = {
   username: reportFilter.username,
   from: reportFilter.from.trim() === '' ? null : new Date(reportFilter.from).toISOString(),
   to: reportFilter.to.trim() === '' ? null : new Date(reportFilter.to).toISOString()
  };

  const response = await postAgentToPlayerTransactionReportFilter(request);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 useEffect(() => {
  getAgentPlayerReport(username);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="username" className="form-label" style={{ paddingRight: '10px' }}>
        Username:
       </label>
       <input
        type="text"
        className="form-control"
        name="username"
        value={reportFilter.username}
        onChange={onChangeHandler}
       />
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="from" className="form-label" style={{ paddingRight: '10px' }}>
        From:
       </label>
       <input
        type="datetime-local"
        className="form-control"
        name="from"
        value={reportFilter.from}
        onChange={onChangeHandler}
       />
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <label htmlFor="to" className="form-label" style={{ paddingRight: '10px' }}>
        To:
       </label>
       <input
        type="datetime-local"
        className="form-control"
        name="to"
        value={reportFilter.to}
        onChange={onChangeHandler}
       />
      </div>
     </div>

     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <button
        type="button"
        className="btn"
        style={{
         backgroundColor: '#3498db',
         color: '#fafafa'
        }}
        onClick={reportFilterHandler}>
        Report Filter
       </button>
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id} className="report--tables">
          <td>{i + 1}</td>
          <td>{new Date(el.createdAt).toLocaleString()}</td>
          <td>{el.User !== null ? el.Admin.username : '-'}</td>
          <td>{el.level.replace(/_/g, ' ')}</td>
          <td>{el.Player.username}</td>
          <td>{el.recharged}</td>
          <td>{el.bonusPercent}</td>
          <td>{el.bonusRecharge}</td>
          <td>{el.totalRecharge}</td>
          <td>{el.redeem}</td>
          <td>{el.income}</td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
