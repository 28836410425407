import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Header from '../Common/Header';
import { getGames } from '../../api/games.api';
import { postCreateGameAssets } from '../../api/assets.api';

function CreateForm() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Add Asset',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  game_name: '-',
  platform: '-'
 });

 const [games, setGames] = useState([]);

 const [files, setFiles] = useState({});

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const fileChangeHandler = (event) => {
  setFiles(event.target.files);
 };

 const submitHandler = async () => {
  let form = new FormData();

  form.append('file', files[0]);
  form.append('name', state.game_name);
  form.append('platform', state.platform);

  const response = await postCreateGameAssets(form);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/game/assets');
  }
 };

 const getGameList = async () => {
  const response = await getGames();
  if (response.status !== 200) errorHandler(response);
  else setGames(response.data);
 };

 useEffect(() => {
  getGameList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="game_name_label" className="form-label">
        Select the game
       </label>
       <select
        className="form-select"
        name="game_name"
        id="game_name_label"
        value={state.game_name}
        onChange={onChangeFormHandler}>
        <option value="-" disabled>
         -
        </option>
        {games.map((el) => {
         return (
          <option value={el.name} key={el.id} style={{ textTransform: 'capitalize' }}>
           {el.name}
          </option>
         );
        })}
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="platform_label" className="form-label">
        Platform
       </label>
       <select
        name="platform"
        className="form-select"
        id="platform_label"
        value={state.platform}
        onChange={onChangeFormHandler}>
        <option value="-">-</option>
        <option value="ios">ios</option>
        <option value="android">android</option>
        <option value="web">web</option>
        <option value="windowsPlayer">Windows Player</option>
        <option value="windowsEditor">Windows Editor</option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="assetFile" className="form-label">
        Upload the asset
       </label>
       <input className="form-control" type="file" id="assetFile" onChange={fileChangeHandler} />
      </div>
      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Add Asset
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateForm;
