import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getTwoFactorStatus() {
 try {
  const { data, status } = await axios.get(`${api}/two-factor/status/check`);
  return { enabled: data.two_factor_status, userId: data.user_id, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postTwoFactorAuthStatus(request) {
 try {
  const { data, status } = await axios.post(`${api}/two-factor/status/update`, request);
  return { enabled: data.enabled, qr: data.qr, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postTwoFactorVerifyToken(request) {
 try {
  const { data, status } = await axios.post(`${api}/two-factor/verify`, request);
  return { enabled: data.enabled, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { getTwoFactorStatus, postTwoFactorAuthStatus, postTwoFactorVerifyToken };
