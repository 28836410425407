import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

// Daily Spin Reports (All)

async function getDailySpinRecords(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/game/daily-spin?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Daily Spin Reports (With Username)

async function getDailySpinRecordWithUsername(query) {
 try {
  let { data, status } = await axios.get(`${api}/report/game/daily-spin/filter?username=${query}`);
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

// Daily Spin Report Filter With (Username, From & To)

async function postGameTransactionReportFilter(request) {
 try {
  let { data, status } = await axios.post(
   `${api}/report/game/daily-spin/transaction/filter`,
   request
  );
  return { data: data.data, pages: data.pages, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { getDailySpinRecords, getDailySpinRecordWithUsername, postGameTransactionReportFilter };
