import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from '../Components/Login/Login';
import ProtectedRoute from '../Components/Authentication/ProtectedRoute';
/*
 * This is the route utility component used for generating
 * routes and child routes it only requires routes array and basePath
 */
function MapAllowedRoutes({ routes }) {
 return (
  <Routes>
   <Route exact path="/auth/login" element={<Login />} />
   <Route exact path="/" element={<ProtectedRoute />}>
    {routes.map((route) => {
     const { path, component: Component, children, title, permission, ...rest } = route;
     return <Route element={Component} {...rest} key={path} path={`${path}`} />;
    })}
   </Route>
  </Routes>
 );
}

export default memo(MapAllowedRoutes);
