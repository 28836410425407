import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getFortuneWheelRecords() {
 try {
  let { data, status } = await axios.get(`${api}/fortune-wheel`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getFortuneWheelWithID(query) {
 try {
  let { data, status } = await axios.get(`${api}/fortune-wheel/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateFortuneWheel(request) {
 try {
  let { data, status } = await axios.post(`${api}/fortune-wheel/create`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdateFortuneWheel(request) {
 try {
  let { data, status } = await axios.patch(`${api}/fortune-wheel/update`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getFortuneWheelRecords,
 getFortuneWheelWithID,
 postCreateFortuneWheel,
 patchUpdateFortuneWheel
};
