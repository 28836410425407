import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../Common/Header';
import { getMediaLinksWithID, patchUpdateMediaLinks } from '../../api/media_links.api';

function UpdateForm() {
 const api =
  process.env.REACT_APP_ENV === 'production'
   ? process.env.REACT_APP_PROD_URL
   : process.env.REACT_APP_DEV_URL;

 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Media Links',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  title: '',
  url: '',
  path: ''
 });

 const [files, setFiles] = useState({});

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const fileChangeHandler = (event) => {
  setFiles(event.target.files);
 };

 const submitHandler = async () => {
  let form = new FormData();

  form.append('id', params.id);
  form.append('title', state.title);
  form.append('url', state.url === null ? '' : state.url);
  form.append('file', files[0]);

  const response = await patchUpdateMediaLinks(form);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/media/links');
  }
 };

 const getMediaLink = async () => {
  const response = await getMediaLinksWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   console.log(response);
   let filePath = `${api}/api/v1/resources/${response.data.file_public_url}`;

   setState({
    title: response.data.title,
    url: response.data.url,
    path: filePath,
    file: response.data.file_name
   });
  }
 };

 useEffect(() => {
  getMediaLink();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="name_label" className="form-label">
        Title
       </label>
       <input
        type="text"
        className="form-control"
        id="name_label"
        name="title"
        value={state.title}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="url_label" className="form-label">
        URL
       </label>
       <input
        type="url"
        className="form-control"
        id="url_label"
        name="url"
        value={state.url}
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="assetFile" className="form-label">
        Upload File
       </label>
       <input className="form-control" type="file" id="assetFile" onChange={fileChangeHandler} />
       {state.path !== null ? (
        <div style={{ marginTop: '20px' }}>
         <h4 style={{ paddingBottom: '5px' }}>Existing Attachments</h4>
         <a
          style={{ textDecoration: 'underline' }}
          href={`${state.path}`}
          target="_blank"
          rel="noreferrer">
          {state.file}
         </a>
        </div>
       ) : null}
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Media Links
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default UpdateForm;
