import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getGames, patchGameStatus } from '../../api/games.api';

function Table() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Games',
  button: true,
  link: '/game/create',
  text: 'Create Game'
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Game Name', width: '15%' },
  { title: 'Status', width: '15%' },
  { title: 'Description', width: '45%' },
  { title: 'Action', width: '20%' }
 ];

 const [rows, setRows] = useState([]);
 const [stateUpdate, setStateUpdate] = useState({
  game_status: false
 });

 const [selectedGame, setSelectedGame] = useState({
  id: null,
  name: '',
  status: ''
 });

 const [searchGame, setSearchGame] = useState('');

 const [status, setStatus] = useState('ACTIVE');

 const getGameInfoHandler = (index) => {
  const selected = rows[index];
  setSelectedGame({
   id: selected.id,
   name: selected.name,
   status: selected.status
  });
 };

 const onChangeStatusHandler = (e) => {
  setStatus(e.target.value);
 };

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   //   history.push({
   //     pathname: "/auth/login",
   //     state: { details: "Login Expires !! Re-Enter the credentials." }
   //   });
   throw new Error('Login Expires !!');
  }
 };

 let getAllGameList = async (query) => {
  let response = await getGames(query);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const onChangeHandler = async (e) => {
  setSearchGame(e.target.value);
  getAllGameList(e.target.value);
 };

 const onSubmitHandler = async () => {
  let request = {
   game_id: selectedGame.id,
   status
  };
  const response = await patchGameStatus(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setStateUpdate({ game_status: true });
  }
 };

 const modalCloseHandler = () => {
  setSelectedGame({
   id: null,
   name: '',
   status: ''
  });

  setStateUpdate({
   game_status: false
  });
 };

 useEffect(() => {
  getAllGameList();

  return () => {
   setStateUpdate({ game_status: false });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [stateUpdate.game_status]);

 return (
  <Layout>
   <div
    className="modal fade"
    id="gameStatusModal"
    tabIndex="-1"
    aria-labelledby="gameStatusModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Change Game Status</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       <div className="mb-3 row">
        <label htmlFor="username_reset" className="col-sm-2 col-form-label">
         Game
        </label>
        <div className="col-sm-10">
         <input
          type="text"
          className="form-control"
          id="username_reset"
          value={selectedGame.name}
          readOnly
         />
        </div>
       </div>
       <form>
        <div className="mb-3 row">
         <label htmlFor="password" className="col-sm-2 col-form-label">
          Current Status
         </label>
         <div className="col-sm-10">
          <input
           type="text"
           className="form-control"
           id="password"
           name="current_status"
           value={selectedGame.status}
           readOnly
          />
         </div>
        </div>
        <div className="mb-3 row">
         <label htmlFor="updated_status" className="col-sm-2 col-form-label">
          Change Status
         </label>
         <div className="col-sm-10">
          <select
           className="form-select"
           id="updated_status"
           name="updated_status"
           value={status}
           onChange={onChangeStatusHandler}>
           <option value="ACTIVE">ACTIVE</option>
           <option value="DISABLE">DISABLE</option>
           <option value="WORKING">WORKING</option>
           <option value="COMING_SOON">COMING SOON</option>
          </select>
         </div>
        </div>
       </form>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={onSubmitHandler}>
        Change Status
       </button>
      </div>
     </div>
    </div>
   </div>

   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <input
        type="text"
        className="form-control"
        name="game_name"
        placeholder="Search From Game Name"
        value={searchGame}
        onChange={onChangeHandler}
       />
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        let status_text_color = '#27ae60';

        if (el.status === 'DISABLE') status_text_color = '#e74c3c';
        else if (el.status === 'COMING_SOON') status_text_color = '#e67e22';
        else if (el.status === 'WORKING') status_text_color = '#2980b9';

        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td style={{ textTransform: 'capitalize' }}>{el.name}</td>
          <td>
           <div className="col">
            <div
             className="col"
             style={{
              fontWeight: '600',
              color: status_text_color
             }}>
             <button
              type="button"
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#gameStatusModal"
              style={{
               backgroundColor: status_text_color,
               color: '#fafafa'
              }}
              onClick={() => getGameInfoHandler(i)}>
              {el.status}
             </button>
            </div>
           </div>
          </td>
          <td>{el.description === null || el.description === '' ? '-' : el.description}</td>
          <td>
           <div className="container">
            <div className="row">
             <div className="col">
              <Link to={`/game/update/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
