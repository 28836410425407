import React, { useState } from 'react';
import Layout from '../../Components/Common/Layout';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../Common/Header';
import { getGameAssetWithID, patchGameAssets } from '../../api/assets.api';
import { useEffect } from 'react';

function UpdateForm() {
 const api =
  process.env.REACT_APP_ENV === 'production'
   ? `${process.env.REACT_APP_PROD_URL}/api/v1`
   : `${process.env.REACT_APP_DEV_URL}/api/v1`;

 const navigate = useNavigate();
 const params = useParams();

 const header_metadata = {
  heading: 'Update Asset',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  id: params.id,
  game_name: '-',
  platform: '-',
  path: ''
 });

 const [files, setFiles] = useState({});

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const fileChangeHandler = (event) => {
  setFiles(event.target.files);
 };

 const submitHandler = async () => {
  let form = new FormData();

  form.append('id', params.id);
  form.append('file', files[0]);
  form.append('name', state.game_name);
  form.append('platform', state.platform);

  const response = await patchGameAssets(form);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/game/assets');
  }
 };

 const getGameAsset = async () => {
  const response = await getGameAssetWithID(params.id);
  if (response.status !== 200) errorHandler(response);
  else {
   setState({
    game_name: response.data.Game.name,
    platform: response.data.platform,
    path: response.data.path,
    file: response.file
   });
  }
 };

 useEffect(() => {
  getGameAsset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="game_name_label" className="form-label">
        Select the game
       </label>
       <select
        className="form-select"
        name="game_name"
        id="game_name_label"
        value={state.game_name}
        onChange={onChangeFormHandler}>
        <option value={state.game_name} disabled>
         {state.game_name}
        </option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="platform_label" className="form-label">
        Platform
       </label>
       <select
        name="platform"
        className="form-select"
        id="platform_label"
        value={state.platform}
        onChange={onChangeFormHandler}>
        <option value="-">-</option>
        <option value="ios">ios</option>
        <option value="android">android</option>
        <option value="web">web</option>
        <option value="windowsPlayer">Windows Player</option>
        <option value="windowsEditor">Windows Editor</option>
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="assetFile" className="form-label">
        Upload the assets
       </label>
       <input className="form-control" type="file" id="assetFile" onChange={fileChangeHandler} />
       {state.path !== null ? (
        <div style={{ marginTop: '20px' }}>
         <h4 style={{ paddingBottom: '5px' }}>Existing Assets</h4>
         <a style={{ textDecoration: 'underline' }} href={`${api}${state.path}`}>
          {state.file}
         </a>
        </div>
       ) : null}
      </div>
      <button type="button" className="btn btn-warning" onClick={submitHandler}>
       Update Asset
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default UpdateForm;
