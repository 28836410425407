export const Role = {
 SUPER_ADMIN: 'SUPER_ADMIN',
 DISTRIBUTOR: 'DISTRIBUTOR',
 SUB_DISTRIBUTOR: 'SUB_DISTRIBUTOR',
 STORE: 'STORE',

 SUPER_ADMIN_MODERATOR_EDITOR: 'SUPER_ADMIN_MODERATOR_EDITOR',
 SUPER_ADMIN_MODERATOR_AUDITOR: 'SUPER_ADMIN_MODERATOR_AUDITOR',
 SUPER_ADMIN_MODERATOR_CASHIER: 'SUPER_ADMIN_MODERATOR_CASHIER',
 SUPER_ADMIN_MODERATOR_DEVELOPER: 'SUPER_ADMIN_MODERATOR_DEVELOPER',

 DISTRIBUTOR_MODERATOR_EDITOR: 'DISTRIBUTOR_MODERATOR_EDITOR',
 DISTRIBUTOR_MODERATOR_AUDITOR: 'DISTRIBUTOR_MODERATOR_AUDITOR',
 DISTRIBUTOR_MODERATOR_CASHIER: 'DISTRIBUTOR_MODERATOR_CASHIER',

 SUB_DISTRIBUTOR_MODERATOR_EDITOR: 'SUB_DISTRIBUTOR_MODERATOR_EDITOR',
 SUB_DISTRIBUTOR_MODERATOR_AUDITOR: 'SUB_DISTRIBUTOR_MODERATOR_AUDITOR',
 SUB_DISTRIBUTOR_MODERATOR_CASHIER: 'SUB_DISTRIBUTOR_MODERATOR_CASHIER',

 STORE_MODERATOR_EDITOR: 'STORE_MODERATOR_EDITOR',
 STORE_MODERATOR_AUDITOR: 'STORE_MODERATOR_AUDITOR',
 STORE_MODERATOR_CASHIER: 'STORE_MODERATOR_CASHIER'
};
