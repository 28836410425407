import React, { useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getGameAssets } from '../../api/assets.api';

function Table() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Game Assets',
  button: true,
  link: '/game/assets/create',
  text: 'Add Assets'
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Game Name', width: '15%' },
  { title: 'Platform', width: '45%' },
  { title: 'Action', width: '35%' }
 ];

 const [rows, setRows] = useState([]);

 const [searchGame, setSearchGame] = useState('');

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeHandler = async (e) => {
  setSearchGame(e.target.value);
  getAllGameAssets(e.target.value);
 };

 let getAllGameAssets = async (query) => {
  let response = await getGameAssets(query);
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 useEffect(() => {
  getAllGameAssets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <input
        type="text"
        className="form-control"
        name="game_name"
        placeholder="Search From Game Name"
        value={searchGame}
        onChange={onChangeHandler}
       />
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table
      id="dtBasicExample"
      className="table table-striped table-bordered table-sm"
      cellSpacing="0"
      width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.Game.name}</td>
          <td style={{ textTransform: 'capitalize' }}>{el.platform}</td>
          <td>
           <div className="container" style={{ width: '50%' }}>
            <div className="row">
             <div className="col">
              <Link to={`/game/assets/update/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link>
             </div>
             <div className="col">
              <button type="button" className="btn btn-danger">
               Delete
              </button>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
