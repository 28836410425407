import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../Common/Header';
import Layout from '../../Components/Common/Layout';

import { postCreateFishServerAccount } from '../../api/fish_server.api';
import { getGames } from '../../api/games.api';

function CreateFishServerAccount() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Create Server Account',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  game_id: '-',
  username: '',
  password: ''
 });

 const [games, setGames] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeHandler = async function (e) {
  const name = e.target.name;
  const value = e.target.value;

  setState({
   ...state,
   [name]: value
  });
 };

 const submitHandler = async () => {
  let request = {
   game_id: state.game_id,
   username: state.username,
   password: state.password
  };
  const response = await postCreateFishServerAccount(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   navigate('/game/fish-server/accounts');
  }
  console.log(request);
 };

 const getGameList = async () => {
  const response = await getGames();
  if (response.status !== 200) errorHandler(response);
  else setGames(response.data);
 };

 useEffect(() => {
  getGameList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <div className="form-row">
      <div className="mb-3">
       <label htmlFor="game_name_label" className="form-label">
        Select the game
       </label>
       <select
        className="form-select"
        name="game_id"
        id="game_name_label"
        onChange={onChangeHandler}
        value={state.game_id}>
        <option value="-" disabled>
         -
        </option>
        {games.map((el) => {
         return (
          <option value={el.id} key={el.id} style={{ textTransform: 'capitalize' }}>
           {el.name}
          </option>
         );
        })}
       </select>
      </div>
      <div className="mb-3">
       <label htmlFor="username" className="form-label">
        Username
       </label>
       <input
        type="text"
        className="form-control"
        id="username"
        name="username"
        value={state.username}
        onChange={onChangeHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="password" className="form-label">
        Password
       </label>
       <input
        type="text"
        className="form-control"
        id="password"
        name="password"
        value={state.password}
        onChange={onChangeHandler}
       />
      </div>
      <button type="button" className="btn btn-primary" onClick={submitHandler}>
       Create Account
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
}

export default CreateFishServerAccount;
