import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import auth from "./Auth";

function ProtectedRoute() {
  const isAuth = auth.isAuthenticated();

  return isAuth ? <Outlet /> : <Navigate to="/auth/login" />;
}

export default ProtectedRoute;
