import axios from 'axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

let client = localStorage.getItem('client');
let access_token = null;

if (client !== null) {
 client = JSON.parse(client);
 access_token = client.access_token;
}

let client_ip;

// --------------- STORE IP: IN REDIS -------------------
// async function getClientIp() {
//   const response = await axios.get("https://api.ipify.org/?format=json");
//   const { data } = response;
//   client_ip = data.ip;
// }

// getClientIp();

const instance = axios.create({
 baseURL: api
});

// let impersonate = JSON.parse(localStorage.getItem("impersonated_user"));
// if (impersonate !== null) {
//   instance.defaults.headers.common["Impersonate"] = `Bearer ${impersonate.token}`;
// }

instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
instance.defaults.headers.common['Client'] = client_ip;
instance.defaults.headers.common['Content-Type'] = 'application/json';

export default instance;
