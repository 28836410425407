import React, { useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAnnouncementsAdmin, deleteAnnouncements } from '../../api/announcement.api';

function Table() {
 const navigate = useNavigate();

 const [apiStatus, setApiStatus] = useState(false);

 const header_metadata = {
  heading: 'Announcements',
  button: true,
  link: '/admin/create/announcement',
  text: 'Create Announcement'
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Announcement Type', width: '12%' },
  { title: 'Announcement Text', width: '45%' },
  { title: 'Action', width: '35%' }
 ];

 const [rows, setRows] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   //   history.push({
   //     pathname: "/auth/login",
   //     state: { details: "Login Expires !! Re-Enter the credentials." }
   //   });
   throw new Error('Login Expires !!');
  }
 };

 let getAllAdminAnnouncements = async () => {
  let response = await getAnnouncementsAdmin();
  if (response.status !== 200) errorHandler(response);
  else {
   setRows(response.data);
  }
 };

 const deleteAnnouncementHandler = async (id) => {
  const response = await deleteAnnouncements(id);
  if (response.status !== 200) errorHandler(response);
  else setApiStatus(true);
 };

 useEffect(() => {
  getAllAdminAnnouncements();

  return () => {
   setApiStatus(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [apiStatus]);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <table
      id="dtBasicExample"
      className="table table-striped table-bordered table-sm"
      cellSpacing="0"
      width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.type}</td>
          <td>{el.announcement}</td>
          <td>
           <div className="container" style={{ width: '50%' }}>
            <div className="row">
             <div className="col">
              <Link to={`/admin/update/announcement/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link>
             </div>
             <div className="col">
              <button
               type="button"
               className="btn btn-danger"
               onClick={() => deleteAnnouncementHandler(el.id)}>
               Delete
              </button>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
