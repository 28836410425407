import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getClubs } from '../../api/clubs.api';

function Table() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Clubs',
  button: false,
  link: null,
  text: ''
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Name', width: '20%' },
  { title: 'Ranking Orders', width: '15%' },
  { title: 'Default', width: '15%' },
  { title: 'Bonus', width: '15%' },
  { title: 'Action', width: '30%' }
 ];

 const [rows, setRows] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 let getClubsList = async () => {
  let response = await getClubs();
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 useEffect(() => {
  getClubsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        const button =
         el.isDefault === true ? (
          <button className="btn btn-primary" style={{ padding: '5px' }}>
           Default
          </button>
         ) : (
          '-'
         );

        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.name}</td>
          <td>{el.order}</td>
          <td>{button}</td>
          <td>{`${el.bonus}%`}</td>
          <td>
           <div className="container">
            <div className="row">
             <div className="col">
              <Link to={`/club/update/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
