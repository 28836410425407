import axios from 'axios';
import axios1 from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function postLogin(request) {
 try {
  const { data, status } = await axios.post(`${api}/auth/login`, request);
  return { data: data.data, token: data.token, message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postAgentPasswordReset(request) {
 try {
  const { data, status } = await axios1.post(`${api}/agent/reset-password`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postPlayerPasswordReset(request) {
 try {
  const { data, status } = await axios1.post(`${api}/player/reset-password`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export { postLogin, postAgentPasswordReset, postPlayerPasswordReset };
