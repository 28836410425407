import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
 const [user, setUser] = useState('');

 useEffect(() => {
  let client = localStorage.getItem('client');
  if (client !== null) {
   client = JSON.parse(client);
   setUser(client.user);
  }
 }, [user]);

 return (
  <>
   <div className="content-row header-login--wrapper">
    <div>
     <h5>Welcome To {process.env.REACT_APP_DASHBOARD_NAME}</h5>
    </div>

    <div className="header-login-info--wrapper">
     <div style={{ textAlign: 'right' }}>{user}</div>
    </div>
   </div>

   {props.metadata.heading !== null ? (
    <div className="content-row content-header--wrapper">
     <div>
      <h3>{props.metadata.heading}</h3>
     </div>
     {props.metadata.button ? (
      <div>
       <Link to={props.metadata.link}>
        <button type="button" className="btn btn-primary">
         {props.metadata.text}
        </button>
       </Link>
      </div>
     ) : null}
    </div>
   ) : null}
  </>
 );
}

export default Header;
