import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../Common/Header';
import Layout from '../../Components/Common/Layout';

import {
 getFishServerAccounts,
 postResetPasswordFishServerAccount,
 deleteFishServerAccount
} from '../../api/fish_server.api';

function Table() {
 const navigate = useNavigate();
 const [isDeleted, setDelete] = useState(false);

 const header_metadata = {
  heading: 'Fish Server Accounts',
  button: true,
  link: '/game/fish-server/account/create',
  text: 'Create Account'
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Game Name', width: '20%' },
  { title: 'Account Name', width: '35%' },
  { title: 'Action', width: '40%' }
 ];

 const [rows, setRows] = useState([]);

 const [resetData, setResetData] = useState({
  id: '',
  new_password: '',
  confirm_password: ''
 });

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeHandler = async function (e) {
  const name = e.target.name;
  const value = e.target.value;

  setResetData({
   ...resetData,
   [name]: value
  });
 };

 let getAccounts = async () => {
  let response = await getFishServerAccounts();
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 const deleteFishAccount = async function (id) {
  const response = await deleteFishServerAccount(id);
  if (response.status !== 200) errorHandler(response);
  else setDelete(true);
 };

 const onSubmitHandler = async () => {
  let request = {
   server_id: resetData.id,
   new_password: resetData.new_password,
   confirm_password: resetData.confirm_password
  };
  const response = await postResetPasswordFishServerAccount(request);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setResetData({
    id: '',
    new_password: '',
    confirm_password: ''
   });
  }
 };

 const resetPasswordModalOpen = (index) => {
  let selected = rows[index];
  setResetData({
   ...resetData,
   id: selected.id
  });
 };

 const modalCloseHandler = () => {
  setResetData({
   id: null,
   new_password: '',
   confirm_password: ''
  });
 };

 useEffect(() => {
  getAccounts();

  return () => {
   setDelete(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isDeleted]);

 return (
  <Layout>
   <div
    className="modal fade"
    id="resetPasswordModal"
    tabIndex="-1"
    aria-labelledby="resetModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Reset Password</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       <form>
        <div className="mb-3 row">
         <label htmlFor="new_password" className="col-sm-2 col-form-label">
          Password
         </label>
         <div className="col-sm-10">
          <input
           type="new_password"
           className="form-control"
           id="new_password"
           name="new_password"
           autoComplete="on"
           onChange={onChangeHandler}
           value={resetData.new_password}
          />
         </div>
        </div>
        <div className="mb-3 row">
         <label htmlFor="confirm_password" className="col-sm-2 col-form-label">
          Confirm Password
         </label>
         <div className="col-sm-10">
          <input
           type="password"
           className="form-control"
           id="confirm_password"
           name="confirm_password"
           autoComplete="on"
           onChange={onChangeHandler}
           value={resetData.confirm_password}
          />
         </div>
        </div>
       </form>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={onSubmitHandler}>
        Reset Password
       </button>
      </div>
     </div>
    </div>
   </div>

   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-filter--wrapper">
     <div className="mb-3">
      <div className="d-inline-flex align-items-center" style={{ width: '80%' }}>
       <input
        type="text"
        className="form-control"
        name="game_name"
        placeholder="Search From Game Name"
       />
      </div>
     </div>
    </div>
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td style={{ textTransform: 'capitalize' }}>{el.Game.name}</td>
          <td>{el.username}</td>
          <td>
           <div className="container" style={{ width: '50%' }}>
            <div className="row">
             <div className="col">
              <button
               type="button"
               className="btn btn-warning"
               data-bs-toggle="modal"
               data-bs-target="#resetPasswordModal"
               onClick={() => resetPasswordModalOpen(i)}>
               Reset Password
              </button>
             </div>
             <div className="col">
              <button
               type="button"
               className="btn btn-danger"
               onClick={() => deleteFishAccount(el.id)}>
               Delete
              </button>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
