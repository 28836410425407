import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Layout from '../../Components/Common/Layout';
import Header from '../Common/Header';

import { getDashboard } from '../../api/dashboard.api';

function Main() {
 const api =
  process.env.REACT_APP_ENV === 'production'
   ? `${process.env.REACT_APP_PROD_URL}/api/v1`
   : `${process.env.REACT_APP_DEV_URL}/api/v1`;

 const navigate = useNavigate();

 const [dashboard, setDashboard] = useState({
  medias: []
 });

 const header_metadata = {
  heading: null,
  button: false,
  link: '',
  text: ''
 };

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 let getDashboardData = async () => {
  let response = await getDashboard();
  if (response.status !== 200) errorHandler(response);
  else setDashboard(response.data);
 };

 useEffect(() => {
  getDashboardData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div>
     <h4 style={{ margin: '20px 0' }}>Overview</h4>
     <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', gap: '30px 0' }}>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Total Registered Player</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Recent Registered Player</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Recharge Amount</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Recharge Spent</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Total Income</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Total Loss</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>

      <div className="card" style={{ width: '18rem' }}>
       <div className="card-body">
        <h6 className="card-title">Total Withdraw Amount</h6>
        <h4 className="card-text">20</h4>
       </div>
      </div>
     </div>
    </div>
    <div>
     <h4 style={{ marginTop: '20px' }}>Media Links</h4>

     {dashboard.medias.map((el) => {
      let file_url = `${api}/resources/${el.file_public_url}`;
      return (
       <div key={el.id} className="card" style={{ margin: '20px 0' }}>
        <div className="card-body">
         <h5 className="card-title">{el.title}</h5>
         <p className="card-text">
          {el.url !== null && el.url !== '' ? (
           <a href={el.url} target="_blank" rel="noreferrer">
            {el.url}
           </a>
          ) : (
           '-'
          )}
         </p>

         {el.file_public_url !== null ? (
          <a href={file_url} target="_blank" rel="noreferrer" className="btn btn-primary">
           Download
          </a>
         ) : (
          '-'
         )}
        </div>
       </div>
      );
     })}
    </div>
   </div>
  </Layout>
 );
}

export default Main;
