import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Layout from '../../Components/Common/Layout';
import Header from '../Common/Header';

import {
 getTwoFactorStatus,
 postTwoFactorAuthStatus,
 postTwoFactorVerifyToken
} from '../../api/2FA.api.js';

function Main() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: '2FA Authentication',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  enabled: '',
  user_id: ''
 });

 const [qr, setQr] = useState({
  shouldShow: false,
  image: ''
 });

 const [token, setToken] = useState('');

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const checkStatus = async function () {
  const response = await getTwoFactorStatus();
  if (response.status !== 200) errorHandler(response);
  setState({
   enabled: response.enabled,
   user_id: response.userId
  });
 };

 const modalCloseHandler = () => {
  setQr({
   shouldShow: false,
   image: ''
  });

  setToken('');
 };

 const changeStatusHandler = async function () {
  let data = {
   status: state.enabled,
   user_id: state.user_id
  };

  const response = await postTwoFactorAuthStatus(data);
  if (response.status !== 200) errorHandler(response);
  if (response.enabled) {
   setQr({
    shouldShow: true,
    image: response.qr
   });
  } else {
   toast.success('2FA has been disabled', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });

   setQr({
    shouldShow: false,
    image: ''
   });
  }
  setState({
   ...state,
   enabled: data.enabled
  });
 };

 const onClickHandler = async function () {
  let data = {
   user_id: state.user_id,
   token
  };

  const response = await postTwoFactorVerifyToken(data);
  if (response.status !== 200) errorHandler(response);
  else {
   setQr({
    shouldShow: false,
    image: ''
   });
   setState({
    ...state,
    enabled: response.enabled
   });
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
  }
 };

 const onChangeHandler = (e) => setToken(e.target.value);

 useEffect(() => {
  checkStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <div
    className="modal fade"
    id="modal"
    tabIndex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-header">
       <h5 className="modal-title">Scan QR From Authenticator Application</h5>
       <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={modalCloseHandler}></button>
      </div>
      <div className="modal-body">
       <div className="mb-3 row">
        {qr.shouldShow ? (
         <div style={{ marginLeft: '17%' }}>
          <img src={qr.image} alt="scan" width="300" height="300" />
         </div>
        ) : null}
       </div>

       <div className="mb-3 row">
        <label htmlFor="auth_token" className="col-sm-2 col-form-label">
         Auth Token
        </label>
        <div className="col-sm-10">
         <input
          type="text"
          className="form-control"
          id="auth_token"
          name="auth_token"
          value={token}
          onChange={onChangeHandler}
         />
        </div>
       </div>
      </div>
      <div className="modal-footer">
       <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={modalCloseHandler}>
        Close
       </button>
       <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        onClick={onClickHandler}>
        Continue
       </button>
      </div>
     </div>
    </div>
   </div>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     {!state.enabled ? (
      <button
       type="button"
       className="btn btn-success"
       data-bs-toggle="modal"
       data-bs-target="#modal"
       onClick={changeStatusHandler}>
       Enable 2FA Authentication
      </button>
     ) : (
      <button type="button" className="btn btn-danger" onClick={changeStatusHandler}>
       Disable 2FA Authentication
      </button>
     )}
    </div>
   </div>
  </Layout>
 );
}

export default Main;
