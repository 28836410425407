import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from '../Authentication/Auth';

import check_account from '../../utils/check_user';

function Login() {
 const user_account = check_account();
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Admin Panel Login',
  button: false,
  link: '',
  text: ''
 };

 const [state, setState] = useState({
  username: '',
  password: '',
  two_FA_token: ''
 });

 const [isLoggedin, setIsLoggedIn] = useState(false);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   //   toast.error(response.message, {
   //     position: "top-right",
   //     autoClose: 5000,
   //     hideProgressBar: true,
   //     closeOnClick: true,
   //     pauseOnHover: true,
   //     draggable: true,
   //     progress: undefined
   //   });
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 const onChangeFormHandler = function (e) {
  let name = e.target.name;
  setState({
   ...state,
   [name]: e.target.value
  });
 };

 const submitHandler = async (e) => {
  e.preventDefault();
  const response = await auth.login(state);
  if (response.status !== 200) errorHandler(response);
  else {
   toast.success(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   setIsLoggedIn(true);
  }
 };

 const isDeveloperAccount = user_account.query_account_type === 'SUPER_ADMIN_MODERATOR_DEVELOPER';
 const isAuditorStoreAccount = user_account.query_account_type.includes('STORE_MODERATOR_AUDITOR');
 const isAuditorAccount = user_account.query_account_type.includes('AUDITOR');
 const isStoreAccount = user_account.query_account_type.includes('STORE');

 const isAuthenticated = auth.isAuthenticated();

 return (
  <div className="content-row">
   {isAuthenticated ? window.location.replace('/agents') : null}

   {isDeveloperAccount && isAuthenticated ? window.location.replace('/games') : null}
   {isAuditorAccount && isAuthenticated
    ? window.location.replace('/report/agent/transactions')
    : null}
   {isStoreAccount && isAuthenticated ? window.location.replace('/players') : null}
   {isAuditorStoreAccount && isAuthenticated ? window.location.replace('/report/jackpot') : null}

   <div className="content-header--wrapper" style={{ marginTop: '20px' }}>
    <div>
     <h3>{header_metadata.heading}</h3>
    </div>
   </div>
   <div className="content-body--wrapper">
    <div className="form-row">
     <form>
      <div className="mb-3">
       <label htmlFor="username_input" className="form-label">
        Username
       </label>
       <input
        type="text"
        className="form-control"
        name="username"
        id="username_input"
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="password_input" className="form-label">
        Password
       </label>
       <input
        type="password"
        className="form-control"
        id="password_input"
        name="password"
        onChange={onChangeFormHandler}
       />
      </div>
      <div className="mb-3">
       <label htmlFor="2fa_input" className="form-label">
        2FA Token
        <span style={{ paddingLeft: '5px', fontSize: '12px', color: '#e74c3c' }}>
         (Note: If you have enable Two FA, then only this field is required)
        </span>
       </label>
       <input
        type="text"
        className="form-control"
        id="2fa_input"
        name="two_FA_token"
        onChange={onChangeFormHandler}
       />
      </div>
      <button type="submit" className="btn btn-primary" onClick={submitHandler}>
       Login
      </button>
     </form>
    </div>
   </div>
  </div>
 );
}

export default Login;
