import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getFishServerAccounts() {
 try {
  let { data, status } = await axios.get(`${api}/fish-server/accounts`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateFishServerAccount(request) {
 try {
  let { data, status } = await axios.post(`${api}/fish-server/create`, request);
  return { data: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postResetPasswordFishServerAccount(request) {
 try {
  let { data, status } = await axios.post(`${api}/fish-server/reset-password`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function deleteFishServerAccount(query) {
 try {
  let { data, status } = await axios.delete(`${api}/fish-server/delete/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getFishServerAccounts,
 postCreateFishServerAccount,
 postResetPasswordFishServerAccount,
 deleteFishServerAccount
};
