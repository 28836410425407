import React from 'react';

import './chatbox.css';

export default function ChatBox(props) {
 let image = props.random_image;
 let set_active = props.set_active;

 let style = {};

 if (set_active) style.backgroundColor = ' #ecf0f1';

 return (
  <>
   <div className="chatbox-container" style={style}>
    <img src={image} alt="Avatar" className="avatar" />
    <div style={{ display: 'grid', gridTemplateColumns: '80% 20%' }}>
     <div className="chatbox-username">{props.username}</div>
     <div className="chatbox-new-message-icon"></div>
    </div>
   </div>
  </>
 );
}
