import axios from '../Container/Axios/Axios';

const api =
 process.env.REACT_APP_ENV === 'production'
  ? `${process.env.REACT_APP_PROD_URL}/api/v1`
  : `${process.env.REACT_APP_DEV_URL}/api/v1`;

async function getAnnouncementsAdmin() {
 try {
  let { data, status } = await axios.get(`${api}/announcement`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function getAnnouncementsWithID(query) {
 try {
  let { data, status } = await axios.get(`${api}/announcement/${query}`);
  return { data: data.data, file: data.file, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function postCreateAnnouncements(request) {
 try {
  let { data, status } = await axios.post(`${api}/announcement`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function patchUpdateAnnouncements(request) {
 try {
  let { data, status } = await axios.patch(`${api}/announcement`, request);
  return { message: data.message, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

async function deleteAnnouncements(query) {
 try {
  let { data, status } = await axios.delete(`${api}/announcement/${query}`);
  return { data: data.data, status };
 } catch (err) {
  const { data, status } = err.response;
  if (status !== 500) {
   return { message: data.message, status };
  }
  console.log('Internal Server Error !! Please Render it to different component');
 }
}

export {
 getAnnouncementsAdmin,
 getAnnouncementsWithID,
 postCreateAnnouncements,
 patchUpdateAnnouncements,
 deleteAnnouncements
};
