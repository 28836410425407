import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Common/Layout';

import Header from '../Common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getOffers } from '../../api/offers.api';

function Table() {
 const navigate = useNavigate();

 const header_metadata = {
  heading: 'Offers',
  button: true,
  link: '/offer/create',
  text: 'Create Offer'
 };

 const columns = [
  { title: '#', width: '5%' },
  { title: 'Title', width: '15%' },
  { title: 'Value', width: '15%' },
  { title: 'Type', width: '15%' },
  { title: 'Description', width: '30%' },
  { title: 'Action', width: '20%' }
 ];

 const [rows, setRows] = useState([]);

 const errorHandler = async function (response) {
  if (response.status === 400) {
   toast.error(response.message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
   });
   throw new Error(response.message);
  } else if (response.status === 401) {
   localStorage.removeItem('client');
   navigate({
    pathname: '/auth/login'
   });
   throw new Error('Login Expires !!');
  }
 };

 let getOfferList = async () => {
  let response = await getOffers();
  if (response.status !== 200) errorHandler(response);
  else setRows(response.data);
 };

 useEffect(() => {
  getOfferList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Layout>
   <Header metadata={header_metadata} />
   <div className="content-row">
    <div className="content-body--wrapper">
     <table className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
      <thead>
       <tr>
        {columns.map((el, i) => (
         <th key={i} className="th-sm" width={el.width}>
          {el.title}
         </th>
        ))}
       </tr>
      </thead>
      <tbody>
       {rows.map((el, i) => {
        return (
         <tr key={el.id}>
          <td>{i + 1}</td>
          <td>{el.title}</td>
          <td>
           <div className="col">{el.value}</div>
          </td>
          <td>{el.type}</td>
          <td>{el.description === null || el.description === '' ? '-' : el.description}</td>
          <td>
           <div className="container">
            <div className="row">
             <div className="col">
              <Link to={`/offer/update/${el.id}`}>
               <button type="button" className="btn btn-warning">
                Update
               </button>
              </Link>
             </div>
            </div>
           </div>
          </td>
         </tr>
        );
       })}
      </tbody>
     </table>
    </div>
   </div>
  </Layout>
 );
}

export default Table;
